import React, { useState } from "react";
import { Button, TextField, Divider, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import "../../../Styles/ChallengemodeStyles/ChallengeMode.css";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import baseurl from "../../../Config";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import * as Actions from "../../../Redux/Actions";
// import DateTimePicker from 'react-datetime-picker';
// import Modal from "@mui/material/Modal";

const ChallengeMode = (props) => {
  var CryptoJS = require("crypto-js");
  const betslipdata = localStorage.getItem("betslipdata");
  var bytes = betslipdata && CryptoJS.AES.decrypt(betslipdata, "dt-crg-ggp2p");
  var decryptedBSData =
    betslipdata && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const close = props.close;
  const gamedate = decryptedBSData && decryptedBSData[0].matchdate;
  const [dd, mm, yyyy] = gamedate && gamedate.split(".");
  const convertedmatchdate = yyyy + "-" + mm + "-" + dd;
  const setExptime = useSelector((state) => state.setExptime);
  const setExptValue = useSelector((state) => state.setExptValue);
  const [value, setValue] = React.useState(() => {
    return setExptime
      ? setExptValue
      : dayjs(
          decryptedBSData &&
            dayjs(convertedmatchdate + "T" + decryptedBSData[0].matchtime)
        );
  });
  const userData = useSelector((state) => state.addUser);
  const groupData = useSelector((state) => state.groupUser);
  const selectedUserData = useSelector((state) => state.selectedUserData);
  const challenger = useSelector((state) => state.showChallenger);
  const isActive = useSelector((state) => state.isPrivateChallenge);
  const gamedetails = localStorage.getItem("gamedetails");
  const gamebytes = CryptoJS.AES.decrypt(gamedetails, "dt-crg-ggp2p");
  const decryptedGamedata = JSON.parse(gamebytes.toString(CryptoJS.enc.Utf8));
  const expiredate = value.$d;
  const converteddate = expiredate && expiredate.toISOString().split("T");
  const expiretime = Math.floor(expiredate.getTime() / 1000);
  const userdetails = localStorage.getItem("userdetails");
  var bytes = userdetails && CryptoJS.AES.decrypt(userdetails, "dt-crg-ggp2p");
  var userinfo = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // const userinfo = JSON.parse(userdetils);
  // const [challenger, setChallenger] = useState(false);
  const dispatch = useDispatch();

  let currentDate = new Date().toLocaleString();

  //Challenge API Call
  const Access_Token = localStorage.getItem("accesskey");
  const placechallenge = (name) => {
    dispatch(Actions.sendIsLoading(true));
    var publicdata = {
      type_of_challenge: name,
      expiration_date: converteddate[0],
      status_of_challenge: 1,
      expiration_time: expiretime,
      user_stack_amount: Number(props.stakeamount),
      bet_slip_challenge: {
        sport_id: decryptedGamedata.sportid,
        sport_name: decryptedGamedata.sportname,
        region_id: decryptedGamedata.regionid,
        competition_id: decryptedGamedata.competitionid,
        game_id: decryptedBSData[0].gameid,
        game_name: `${decryptedBSData[0].team1} vs ${decryptedBSData[0].team2}`,
        team1_name: decryptedBSData[0].team1,
        team1_id: 11,
        team2_id: 22,
        team2_name: decryptedBSData[0].team2,
        market_name: decryptedBSData[0].marketName,
        market_id: decryptedBSData[0].marketid,
        event_name: decryptedBSData[0].eventName,
        event_id: decryptedBSData[0].eventId,
        game_date: convertedmatchdate,
        game_time: decryptedBSData[0].matchtime,
        odds: props.updatedodds[0].price && props.updatedodds[0].price,
      },
    };
    var privatedata = {
      type_of_challenge: name,
      expiration_date: converteddate[0],
      status_of_challenge: 1,
      selected_user:
        selectedUserData && userData && selectedUserData.id === userData.id
          ? userData.id
          : null,
      selected_group:
        selectedUserData && groupData && selectedUserData.id === groupData.id
          ? groupData.id
          : null,
      expiration_time: expiretime,
      user_stack_amount: Number(props.stakeamount),
      bet_slip_challenge: {
        sport_id: decryptedGamedata.sportid,
        sport_name: decryptedGamedata.sportname,
        region_id: decryptedGamedata.regionid,
        competition_id: decryptedGamedata.competitionid,
        game_id: decryptedBSData[0].gameid,
        game_name: `${decryptedBSData[0].team1} vs ${decryptedBSData[0].team2}`,
        team1_name: decryptedBSData[0].team1,
        team1_id: 11,
        team2_id: 22,
        team2_name: decryptedBSData[0].team2,
        market_name: decryptedBSData[0].marketName,
        market_id: decryptedBSData[0].marketid,
        event_name: decryptedBSData[0].eventName,
        event_id: decryptedBSData[0].eventId,
        game_date: convertedmatchdate,
        game_time: decryptedBSData[0].matchtime,
        odds: props.updatedodds[0].price && props.updatedodds[0].price,
      },
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(Access_Token)}`,
    };
    axios
      .post(
        `${baseurl.baseurl}challenge/`,
        name === "Public" ? { ...publicdata } : { ...privatedata },
        { headers }
      )
      .then(function (response) {
        dispatch(Actions.sendIsLoading(false));
        toast.success(response.data.message, { duration: 1000 });
        dispatch(Actions.selectedUserData(""));
        dispatch(Actions.addUserData(""));
        dispatch(Actions.sendbetslipdata(""));
        localStorage.setItem("betslipdata", "");
        props.setstakeamount("");
      })
      .catch(function (error) {
        toast.error("something went wrong", { duration: 1000 });
      });
    close();
  };

  const convertinputdate = (datetimevalue) => {
    dispatch(Actions.setExptime(true));
    dispatch(Actions.setExptValue(datetimevalue));
    setValue(datetimevalue);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <div className="ChallengeAFriendRoot">
      <div className="ChallengeAFriend">
        <div style={{ flexGrow: 1 }}>
          <div className="ChallengedSport">{decryptedGamedata.sportname}</div>
          <div className="ChallengedVersus">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <PersonIcon />
              </div>
              <div style={{ fontWeight: "600" }}>{userinfo.username}</div>
            </div>
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  color: "rgba(0,0,0,0.4)",
                  fontSize: "1rem",
                }}
              >
                VS
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <PersonIcon />
              </div>
              <div>
                {selectedUserData ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "600",
                    }}
                  >
                    {selectedUserData.name || selectedUserData.usernumber}
                    <ClearIcon
                      style={{ cursor: "pointer", fontSize: "0.8em" }}
                      onClick={() => {
                        dispatch(Actions.selectedUserData(""));
                        dispatch(Actions.addUserData(""));
                        dispatch(Actions.isPrivateChallenge(false));
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      fontWeight: "600",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={(e) => {
                      props.adduser(1);
                      dispatch(Actions.showChallenger(false));
                    }}
                  >
                    Add User
                    <AddIcon style={{ fontSize: "13px" }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flexGrow: 2 }}>
          <div
            style={{
              margin:
                currentDate >=
                dayjs(
                  convertedmatchdate + "T" + decryptedBSData[0].matchtime
                ).$d.toLocaleString()
                  ? "10px"
                  : "20px 10px",
            }}
          >
            {decryptedBSData &&
              decryptedBSData.map((data, index) => {
                return (
                  <div key={index}>
                    <div className="ChallengeBetslip">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "5px 0",
                        }}
                      >
                        <div
                          className="Betslipteamsnames"
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {data.team1}&nbsp;-&nbsp;{data.team2}
                        </div>
                      </div>
                      <div className="BetslipMatchDatetimechallengemode">
                        <div>
                          {data.matchdate},{data.matchtime}
                        </div>
                      </div>
                      <div className="BetslipMarketName">
                        <div
                          style={{
                            marginTop: 4,
                            fontWeight: "600",
                            color: "#00000066",
                          }}
                        >
                          {data.marketName}
                        </div>
                      </div>
                      <div
                        className="BetslipMatchbtw"
                        style={{
                          marginTop: 4,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div className="BetslipEventName">{data.eventName}</div>
                        <div className="BetslipEventPrice">
                          {props.updatedodds[0].price &&
                            props.updatedodds[0].price}
                        </div>
                      </div>
                    </div>
                    <Divider
                      style={{ backgroundColor: "#bfbfbf", marginTop: 10 }}
                    />
                    <div>
                      <div
                        style={{
                          color: "#000000",
                          fontWeight: 600,
                          fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          margin: "15px 0px",
                        }}
                      >
                        Expiration Date & Time
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Date time library */}
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              inputFormat="DD.MM.YYYY HH:mm"
                              renderInput={(params) => (
                                <TextField
                                  onKeyDown={onKeyDown}
                                  {...params}
                                  size="small"
                                />
                              )}
                              PopperProps={{
                                placement: "top",
                              }}
                              label="select the expiry data and time"
                              value={value}
                              onChange={(newValue) => {
                                convertinputdate(newValue);
                              }}
                              ampm={false}
                              // readOnly={true}
                              minDateTime={dayjs(new Date().toISOString())}
                              maxDateTime={dayjs(
                                convertedmatchdate + "T" + data.matchtime
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                            fontSize: "14px",
                            fontWeight: "bold",
                            paddingTop: 10,
                          }}
                        >
                          Possible Win:&nbsp;
                          <span
                            style={{
                              fontWeight: 100,
                              fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                              fontSize: "14px",
                            }}
                          >
                            {props.challengepossiblewin} FRw
                          </span>
                        </div>
                      </div>
                      {currentDate >=
                        dayjs(
                          convertedmatchdate +
                            "T" +
                            decryptedBSData[0].matchtime
                        ).$d.toLocaleString() && (
                        <div className="matchexpire-container">
                          <div
                            style={{
                              padding: "0px",
                            }}
                          >
                            <ReportProblemIcon
                              size="small"
                              style={{ color: "red", fontSize: "14px" }}
                            />
                          </div>
                          <span className="matchexpire-text">
                            Deadline Passed!!, you can't place this challenge
                            anymore. Select another challenge to play.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="ChallengeButtons">
          <div>
            <Button
              className="challenge-btn"
              variant="contained"
              size="large"
              style={{
                background:
                  isActive ||
                  currentDate >=
                    dayjs(
                      convertedmatchdate + "T" + decryptedBSData[0].matchtime
                    ).$d.toLocaleString()
                    ? "rgba(0,0,0,0.1)"
                    : "#1C226B",
                color:
                  isActive ||
                  currentDate >=
                    dayjs(
                      convertedmatchdate + "T" + decryptedBSData[0].matchtime
                    ).$d.toLocaleString()
                    ? "rgba(0,0,0,.5)"
                    : "#ffffff",
              }}
              disabled={
                isActive ||
                currentDate >=
                  dayjs(
                    convertedmatchdate + "T" + decryptedBSData[0].matchtime
                  ).$d.toLocaleString()
              }
              onClick={() => {
                // handlechallengename("Public")
                placechallenge("Public");
              }}
            >
              Public Challenge
            </Button>
          </div>
          <div>
            <Button
              className="challenge-btn"
              variant="contained"
              size="large"
              disabled={
                !isActive ||
                currentDate >=
                  dayjs(
                    convertedmatchdate + "T" + decryptedBSData[0].matchtime
                  ).$d.toLocaleString()
              }
              style={{
                color:
                  !isActive ||
                  currentDate >=
                    dayjs(
                      convertedmatchdate + "T" + decryptedBSData[0].matchtime
                    ).$d.toLocaleString()
                    ? "rgba(0,0,0,.5)"
                    : "#ffffff",
                background:
                  !isActive ||
                  currentDate >=
                    dayjs(
                      convertedmatchdate + "T" + decryptedBSData[0].matchtime
                    ).$d.toLocaleString()
                    ? "rgba(0,0,0,0.1)"
                    : "#1C226B",
              }}
              onClick={() => {
                // handlechallengename("Private")
                placechallenge("Private");
              }}
            >
              Private Challenge
            </Button>
            {/* <Modal
              open={modalopen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography>Add this use</Typography>
              </Box>
            </Modal> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeMode;
