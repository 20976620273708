import React from "react";
import LeaderboardTabs from "../components/LeaderboardComp/LeaderboardTabs";
import "../Styles/LeaderboardStyles/Leaderboard.css";
import * as Actions from "../Redux/Actions";
import { useDispatch } from "react-redux";

const Leaderboard = () => {
  const dispatch = useDispatch();
  dispatch(Actions.showMatchData(true));
  // dispatch(Actions.senduserseldata(""))
  return (
    <div className="leaderboardTabs-container">
      <LeaderboardTabs />
    </div>
  );
};

export default Leaderboard;
