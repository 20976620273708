import * as React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddNewMembers from "./AddNewMember";
import GroupMembers from "./groupmemberslist";
import "../../../Styles/FriendsModule/FriendsModule.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "inherit" }}>
          <div style={{ height: "inherit" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditModelTabs(props) {
  const [value2, setValue2] = React.useState(0);
  const closeModal = props.onclose;

  const handleChange = (event, newValue) => {
    setValue2(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", background: "#ffffff" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "600px",
          }}
          className="editGroup-tabs-box"
        >
          <Tabs
            value={value2}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ sx: { bgcolor: "#EC1087" } }}
            sx={{ minHeight: "40px", display: "flex", alignItems: "center" }}
          >
            <Tab
              label="Group Members"
              {...a11yProps(0)}
              style={{
                color: !value2 ? "#ffffff" : "rgb(0,0,0)",
                background: !value2 ? "#EC1087" : "",
                textTransform: "capitalize",
              }}
              className="editGroupTab"
              sx={{ minHeight: "40px" }}
            />
            <Tab
              label="Add New Members"
              {...a11yProps(1)}
              style={{
                color: !value2 ? "rgb(0,0,0)" : "#ffffff",
                background: !value2 ? "" : "#EC1087",
                textTransform: "capitalize",
              }}
              className="editGroupTab"
              sx={{ minHeight: "40px" }}
            />
          </Tabs>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              flex: 1,
              paddingRight: "16px",
              justifyContent: "end",
            }}
          >
            <CloseIcon
              onClick={closeModal}
              style={{ color: "rgb(0,0,0)", height: "20px", width: "20px" }}
            />
          </div>
        </Box>
        <TabPanel value={value2} index={0}>
          <GroupMembers
            close={closeModal}
            groupid={props.groupid}
            editgroupname={props.editgroupname}
            adduser={setValue2}
          />
        </TabPanel>
        <TabPanel value={value2} index={1}>
          <AddNewMembers
            close={closeModal}
            groupid={props.groupid}
            editgroupname={props.editgroupname}
            adduser={setValue2}
          />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
}
