import React, { useState, Fragment } from "react";
import { Paper, Box, IconButton, Stack, Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import Collapse from "@mui/material/Collapse";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "@mui/material/Pagination";
import TableRow from "@mui/material/TableRow";
import "../../../Styles/MyChallengesStyles/MyChallenges.css";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import baseurl from "../../../Config";
import axios from "axios";
import { toast } from "react-hot-toast";
import moment from "moment";

const CreatedChallenges = () => {
  const accesskey = localStorage.getItem("accesskey");
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = useState(-1);
  const [createdchallenges, setcreatedchallenges] = useState("");
  const [responsecount, setresponsecount] = React.useState();
  const otherHeights = 125;
  let tableHeight = window.innerHeight - otherHeights;

  const count = Math.ceil(responsecount / 10);
  const handleChange = (event, value) => {
    setPage(value);
  };

  //get active challnegs list
  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}created-challenges/?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setcreatedchallenges(response.data.results);
        setresponsecount(response.data.count);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [page]);

  return (
    <>
      {createdchallenges ? (
        <div>
          {createdchallenges.length > 0 ? (
            <>
              <TableContainer
                component={Paper}
                style={{
                  maxHeight: tableHeight + "px",
                  overflowY: "overlay",
                }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow className="tableheaders">
                      <TableCell className="table-heading" align="center">
                        Date
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Time
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Sport
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Match
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Type
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Status
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Amount
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {createdchallenges.map((item, index) => (
                    <TableBody key={index}>
                      <TableRow className="tableRowdetails">
                        <TableCell className="table-data" align="center">
                          {moment(item.created_at).format(
                            "DD.MM.yyyy"
                          )}
                        </TableCell>
                        <TableCell className="table-data" align="center">
                          {moment(item.created_at).format("HH:mm")}
                        </TableCell>
                        <TableCell className="table-data" align="center">
                          {item.bet_slip_challenge[0].sport_name}
                        </TableCell>
                        <TableCell className="table-data" align="center">
                          {item.bet_slip_challenge[0].game_name}
                        </TableCell>
                        <TableCell className="table-data" align="center">
                          {item.type_of_challenge}
                        </TableCell>
                        <TableCell className="table-data" align="center">
                          Pending
                        </TableCell>
                        <TableCell className="table-data" align="center">
                          {item.user_stack_amount} FRw
                        </TableCell>
                        <TableCell className="table-data" align="center">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(open === index ? -1 : index)}
                          >
                            {open === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={10}
                          sx={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            border: "0px",
                            background: "#0000001A",
                          }}
                        >
                          <Collapse
                            in={open === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <div className="matchVersus">
                              <div className="matchVersus-child">
                                <div>
                                  <PersonIcon />
                                </div>
                                <div style={{ fontWeight: "600" }}>
                                  {item.created_by.p2p_user_id}
                                </div>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.bet_slip_challenge[0].event_name}
                                </div>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    color: "#1c226b",
                                  }}
                                >
                                  {Number(
                                    item.bet_slip_challenge[0].odds
                                  ).toFixed(2)}
                                </div>
                              </div>
                              <div className="matchVs">
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    color: "#00000066",
                                    fontSize: "11px",
                                  }}
                                >
                                  VS
                                </div>
                                <div style={{ fontWeight: "500" }}>
                                  Expiration Date
                                </div>
                                <div style={{ fontWeight: "500" }}>
                                  {item.expiration_date},{" "}
                                  {item.expiration_converted_time}
                                </div>
                              </div>
                              <div className="matchVersus-child">
                                <div>
                                  <PersonIcon />
                                </div>
                                <div style={{ fontWeight: "600" }}>
                                  {item.selected_group
                                    ? item.selected_group.group_name &&
                                      item.selected_group.group_name
                                    : item.selected_user
                                    ? item.selected_user.p2p_user_id &&
                                      item.selected_user.p2p_user_id
                                    : "Public"}
                                </div>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.bet_slip_challenge[0].event_name}
                                </div>
                                <div
                                  style={{
                                    fontWeight: "500",
                                    color: "#1c226b",
                                  }}
                                >
                                  {Number(
                                    item.bet_slip_challenge[0].odds
                                  ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
              <div className="mychallengepagination">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  size="small"
                  sx={{
                    "& button": {
                      fontSize: "12px",
                      fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                fontSize: "16px",
                fontFamily: "Roboto,Arial,Helvetica,sans-serif",
              }}
            >
              <div>No results found</div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
        </div>
      )}
    </>
  );
};

export default CreatedChallenges;
