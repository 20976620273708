import { FlashOnRounded } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Avatar from '@mui/material/Avatar';


const LeaderboardList = [
  {
    icon:  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />,
    name: "Yuvraj",
  },
  {
    icon: <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />,
    name: "Tendulkar",
  },
  {
    icon: <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />,
    name: "Gangully",
  },
  {
    icon: <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />,
    name: "Kohli",
  },
  {
    icon: <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />,
    name: "Dhoni",
  },
]

const UsersList = [
  {
    Id:1,
    name: "Tendulkar",
    number: "9876543210",
  },
  {
    Id:2,
    name: "Gangully",
    number: "9876543211",
  },
];

const FollowersList = [
  {
    id:'1',
    icon: <AccountCircleIcon />,
    name: "Tendulkar",
    number: "9876543210",
  },
  {
    id:'2',
    icon: <AccountCircleIcon />,
    name: "Gangully",
    number: "9876543211",
  },
];

const FollowingList = [
  {
    icon: <AccountCircleIcon />,
    name: "Sachin",
    number: "9876543213",
  },
  {
    icon: <AccountCircleIcon />,
    name: "Sourav",
    number: "9876543214",
  },
];

const Grpoupslist = [
  {
    icon: <AccountCircleIcon />,
    name: "Group1",
    number: "Members10",
  },
  {
    icon: <AccountCircleIcon />,
    name: "Group2",
    number: "Members10",
  },
];

const notificationData =[
  {
    _id: "1",
    date: "Aug 22, 2022",
    time: "12:40",
    game: "Cricket",
    match: "India vs Pakistan",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "2",
    date: "Aug 22, 2022",
    time: "14:40",
    game: "Cricket",
    match: "India vs Australia",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "3",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs South Africa",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "4",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs NewZealand",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "5",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs England",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "6",
    date: "Aug 22, 2022",
    time: "12:40",
    game: "Cricket",
    match: "India vs Pakistan",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "7",
    date: "Aug 22, 2022",
    time: "14:40",
    game: "Cricket",
    match: "India vs Australia",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "8",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs South Africa",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "9",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs NewZealand",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  },
  {
    _id: "10",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs England",
    type: "Public",
    status: "Active",
    amount: "200",
    read:false
  }
]

const ActiveChallengesdata = [
  {
    _id: "1",
    date: "Aug 22, 2022",
    time: "12:40",
    game: "Cricket",
    match: "India vs Pakistan",
    type: "Public",
    status: "Active",
    amount: "200",
  },
  {
    _id: "2",
    date: "Aug 22, 2022",
    time: "14:40",
    game: "Cricket",
    match: "India vs Australia",
    type: "Public",
    status: "Active",
    amount: "200",
  },
  {
    _id: "3",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs South Africa",
    type: "Public",
    status: "Active",
    amount: "200",
  },
  {
    _id: "4",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs NewZealand",
    type: "Public",
    status: "Active",
    amount: "200",
  },
  {
    _id: "5",
    date: "Aug 22, 2022",
    time: "17:40",
    game: "Cricket",
    match: "India vs England",
    type: "Public",
    status: "Active",
    amount: "200",
  },
];


export {
  UsersList,
  FollowersList,
  FollowingList,
  Grpoupslist,
  ActiveChallengesdata,
  LeaderboardList,
  notificationData
};

