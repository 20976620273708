import React from "react";
import FriendsTabs from "../components/FriendsmoduleComp/Friendstabs";
import * as Actions from "../Redux/Actions";
import { useDispatch } from "react-redux";
import "../Styles/FriendsModule/FriendsModule.css";
const Friends = () => {
  const dispatch = useDispatch();
  dispatch(Actions.showMatchData(true));
  // dispatch(Actions.senduserseldata(""))
  return (
    <div className="friendsTabs-container">
      <FriendsTabs />
    </div>
  );
};
export default Friends;
