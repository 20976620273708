import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Marketsfromgames from "./Marketsfromgames";
import "../../../../Styles/CreateChallengeStyles/Matchdatacontainerstyles/Matchdatacomp.css";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MarketsTypeTabs(props) {
  // const uniquegroupnames = props.uniquegroupnames;
  // const gamedata = props.gamedata;
  const uniquegroupnames = useSelector((state) => state.uniqueGroupNames);
  const gamedata = useSelector((state) => state.gameData);
  const tabnames = props.tabnames;
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  // const [groupname, setgroupname] = React.useState();
  const [marketgroupid, updategroupId] = React.useState(
    uniquegroupnames &&
      uniquegroupnames.length > 0 &&
      uniquegroupnames[0].group_id
      ? uniquegroupnames[0].group_id
      : ""
  );
  // React.useEffect(()=>{
  //   updategroupId(uniquegroupnames&&uniquegroupnames[0].group_id)
  // },[uniquegroupnames])
  const dataselector = useSelector((state) => state.sidemenudata);
  const backtogames = () => {
    props.onclose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handletabs = (id, tabmarketname, tabmarketid) => {
    // setgroupname(tabmarketname);
    // updategroupId(tabmarketid);
  };

  return (
    <React.Fragment>
      {uniquegroupnames.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <div
            className="item-eventview"
            onClick={backtogames}
            style={{ background: "#ffffff", textTransform: "uppercase" }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: "10px",
                background: "#323232",
                marginRight: "10px",
                fontSize: "1em",
              }}
            >
              <KeyboardArrowLeftSharpIcon
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  color: "white",
                  stroke: "white",
                  strokeWidth: 0.5,
                }}
              />
            </div>
            {!mobileView ? (
              <div>
                {dataselector.leaguename} &nbsp; - &nbsp;{gamedata.team1} &nbsp;
                <span style={{ color: "#6c6c6c", fontSize: "11px" }}>vs</span>
                &nbsp;{gamedata.team2}
              </div>
            ) : (
              <div>{dataselector.leaguename}</div>
            )}
          </div>
          {mobileView && (
            <div
              className="item-eventview"
              onClick={backtogames}
              style={{ textTransform: "none" }}
            >
              <div>
                {gamedata.team1} &nbsp;
                <span style={{ color: "#6c6c6c", fontSize: "11px" }}>vs</span>
                &nbsp;{gamedata.team2}
              </div>
            </div>
          )}
          <Box className="marketTabs-box">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                minHeight: "36px",
                display: "flex",
                alignItems: "center",
              }}
              className="market-tabs"
            >
              {uniquegroupnames &&
                uniquegroupnames.map((tab, index) => {
                  return (
                    <Tab
                      key={index}
                      onClick={() =>
                        handletabs(index, tab.group_name, tab.group_id)
                      }
                      label={tab.group_name}
                      {...a11yProps(index)}
                      sx={{ minHeight: "36px" }}
                      className="marketTabs"
                    />
                  );
                })}
            </Tabs>
          </Box>
          {uniquegroupnames &&
            uniquegroupnames.map((tab, index) => {
              return (
                uniquegroupnames &&
                uniquegroupnames[0].group_id && (
                  <TabPanel key={index} value={value} index={index}>
                    <Marketsfromgames
                      {...props}
                      marketgroupid={tab.group_id}
                      gamedata={gamedata}
                    />
                  </TabPanel>
                )
              );
            })}
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            fontFamily: "Roboto,Arial,Helvetica,sans-serif",
          }}
        >
          oops!, no markets found for this, try again!
        </div>
      )}
    </React.Fragment>
  );
}
