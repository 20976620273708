import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  InputAdornment,
  Divider,
  Stack,
  Skeleton,
} from "@mui/material";
import toast from "react-hot-toast";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import baseurl from "../../Config";
import TableHead from "@mui/material/TableHead";
import "../../Styles/FriendsModule/FriendsModule.css";
import { ClassNames } from "@emotion/react";
import CircularProgress from "@mui/material/CircularProgress";

const AddFriends = (props) => {
  const [userData, setUserData] = useState();
  const [searchUser, setSearchUser] = useState("");
  const [loader, setloader] = useState("");
  const [responsecount, setresponsecount] = useState();
  const count = Math.ceil(responsecount / 10);
  const [page, setPage] = React.useState(1);
  const handlepageChange = (event, value) => {
    setPage(value);
  };
  const otherHeights = 185;
  let tableHeight = window.innerHeight - otherHeights;

  const accesskey = localStorage.getItem("accesskey");

  //fetching all usersdata list
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${baseurl.baseurl}get-p2p-user/?page=${page}&search=${searchUser}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${JSON.parse(accesskey)}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setloader(false);
  //       setresponsecount(response.data.count || response.data.results.length);
  //       setUserData(response.data.results);
  //     })
  //     .catch((err) => {});
  // }, [page, searchUser, responsecount]);

  //search user with the phonenumber
  // useEffect(() => {
  //   searchuser();
  // }, [searchUser.length == 0]);

  const searchuser = () => {
    setloader(true);
    axios
      .get(`${baseurl.baseurl}get-p2p-user/?search=${searchUser}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setloader(false);
        // setresponsecount(response.data.count || response.data.results.length);
        setUserData(response.data.results);
      })
      .catch((err) => {});
  };

  // POST request for adding new friend
  const selectNewFriend = (id) => {
    const data = {
      p2p_user: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };
    axios
      .post(`${baseurl.baseurl}p2p-user/add-friend/`, { ...data }, { headers })
      .then(function (response) {
        toast.success(response.data.message, { duration: 1000 });
        const removeuserfromlist = userData.filter(
          (user) => user.p2p_user_id !== id
        );
        if (page > 1 && removeuserfromlist.length === 0) {
          setPage(page - 1);
        }
        setUserData(removeuserfromlist);
        setresponsecount(removeuserfromlist.length);
        props.userdata(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message, { duration: 1000 });
      });
  };

  const textfieldstyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: 1.5,
        borderColor: "#ec1087",
        background: "rgba(0,0,0,0.1)",
      },
      "&:hover fieldset": {
        border: 1.5,
        borderColor: "#ec1087",
      },
    },
  };

  return (
    <React.Fragment>
      <>
        <div className="add-friend-container">
          <div className="search-container">
            <div className="textfield-container">
              <TextField
                sx={textfieldstyle}
                id="outlined-basic"
                size="small"
                type="number"
                placeholder="Search by number"
                value={searchUser}
                onChange={(e) => {
                  setSearchUser(() => e.target.value);
                }}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {loader ? (
                        <CircularProgress size={20} sx={{ color: "#ec1087" }} />
                      ) : (
                        <SearchIcon className="search-icon" />
                      )}
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: "14px",
                    textAlign: "center",
                    color: "rgb(0,0,0,0.8)",
                    background: "white",
                  },
                }}
                inputProps={{ maxLength: 32 }}
              />
              <div className="addbtn-container" style={{ marginLeft: "10px" }}>
                <Button
                  disabled={searchUser.length == 12 ? false : true}
                  type="submit"
                  style={{
                    fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    fontSize: "12px",
                    background:
                      searchUser.length == 12 ? "#1c226b" : "#0000000D",
                    color: "#ffffff",
                    textTransform: "uppercase",
                  }}
                  onClick={searchuser}
                >
                  search
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          {userData && userData.length == 1 ? (
            <>
              <TableContainer
                style={{
                  maxHeight: tableHeight + "px",
                  overflowY: "overlay",
                }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow
                      style={{
                        borderBottom: "1px solid #000000",
                        background: "#f2f2f2",
                      }}
                    >
                      <TableCell className="table-heading" align="center">
                        User
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        User Name
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        User Number
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData &&
                      userData.map((user, id) => {
                        return (
                          <TableRow
                            key={id}
                            style={{
                              borderBottom: "1px solid #0000001f",
                              background: "#f2f2f2",
                            }}
                          >
                            <TableCell className="table-data" align="center">
                              <Avatar
                                alt={user.username}
                                src="/static/images/avatar/1.jpg"
                                sx={{
                                  height: 32,
                                  width: 32,
                                  margin: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {user.p2p_user_id}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {user.p2p_user_id}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {!user.following ? (
                                <Button
                                  className="btn"
                                  variant="contained"
                                  onClick={(e) =>
                                    selectNewFriend(user.p2p_user_id)
                                  }
                                >
                                  select
                                </Button>
                              ) : (
                                <Button
                                  className="btn"
                                  variant="contained"
                                  onClick={() =>
                                    toast.error(
                                      "already exists in friends list",
                                      { duration: 1000 }
                                    )
                                  }
                                >
                                  following
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="pagination">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handlepageChange}
                  size="small"
                  sx={{
                    "& button": {
                      fontSize: "12px",
                      fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    },
                  }}
                />
              </div>
            </>
          ) : (
            userData &&
            userData.length == 0 && (
              <div
                style={{
                  fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                  fontSize: "16px",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                No results found
              </div>
            )
          )}
        </div>
      </>
    </React.Fragment>
  );
};

export default AddFriends;
