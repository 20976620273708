import React, { useState } from "react";
import { Paper, IconButton, Stack, Skeleton, Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//import { ActiveChallengesdata } from "../../../JsonData";
import baseurl from "../../../Config";
import axios from "axios";
import { toast } from "react-hot-toast";
import "./../../../Styles/MyChallengesStyles/MyChallenges.css";

const ListofWon = () => {
  const accesskey = localStorage.getItem("accesskey");
  const [wonChallenges, setWonChallenges] = useState("");
  const [page, setPage] = React.useState(1);
  const [responsecount, setresponsecount] = React.useState();
  const otherHeights = 125;
  let tableHeight = window.innerHeight - otherHeights;
  //get won challnegs list
  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}won-challenges/?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setWonChallenges(response.data.results);
        setresponsecount(response.data.count);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [page]);

  const count = Math.ceil(responsecount / 10);
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {wonChallenges ? (
        <div>
          {wonChallenges.length > 0 ? (
            <>
              <TableContainer
                component={Paper}
                style={{
                  maxHeight: tableHeight + "px",
                  overflowY: "overlay",
                }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow className="tableheaders">
                      <TableCell className="table-heading" align="center">
                        Date
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Time
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Game
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Match
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Type
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Status
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wonChallenges.length > 0 &&
                      wonChallenges.map((item, index) => (
                        <TableRow key={index} className="tableRowdetails">
                          <TableCell className="table-data" align="center">
                            {item.game_date}
                          </TableCell>
                          <TableCell align="center" className="table-data">
                            {item.game_time}
                          </TableCell>
                          <TableCell align="center" className="table-data">
                            {item.sport_name}
                          </TableCell>
                          <TableCell align="center" className="table-data">
                            {item.game_name}
                          </TableCell>
                          <TableCell align="center" className="table-data">
                            {item.challenge.type_of_challenge}
                          </TableCell>
                          <TableCell align="center" className="table-data">
                            Completed
                          </TableCell>
                          <TableCell align="center" className="table-data">
                            {item.stack_amount} FRw
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="mychallengepagination">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  size="small"
                  sx={{
                    "& button": {
                      fontSize: "12px",
                      fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                fontSize: "16px",
                fontFamily: "Roboto,Arial,Helvetica,sans-serif",
              }}
            >
              <div>No results found</div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
        </div>
      )}
    </>
  );
};

export default ListofWon;
