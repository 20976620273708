import React from "react";
import "../../Styles/FriendsModule/FriendsModule.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UsersData from "./FollowersFollowing";
import AddFriends from "./AddFriends";
import ListOfGroups from "./Groups";
import axios from "axios";
import { toast } from "react-hot-toast";
import baseurl from "../../Config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "inherit" }}>
          <div style={{ height: "inherit" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

const FriendsTabs = () => {
  const [value, setValue] = React.useState(0);
  const [userdata, setuserdata] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const accesskey = localStorage.getItem("accesskey");
  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}p2p-user/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setuserdata(response.data);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, []);

  return (
    <div className="friends-tabs-wrapper">
      <Box sx={{ width: { xs: "100%", sm: "95%", md: "90%", lg: "80%" } }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="friendsTabs-box"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="basic tabs example"
            //TabIndicatorProps={{ sx: { bgcolor: "#EC1087" } }}
            className="friends-tabs"
            sx={{
              minHeight: "36px",
              display: "flex",
              alignItems: "center",
              // "& .MuiTabs-scroller": {
              //   display: "flex",
              //   height: "36px",
              //   alignItems: "center",
              // },
              // "& .MuiTabs-flexContainer": {
              //   display: "flex",
              //   height: "36px",
              //   alignItems: "center",
              // },
            }}
          >
            <Tab
              label="Followers"
              className="friendsTabs"
              sx={{
                color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                minHeight: "36px",
              }}
            />
            <Tab
              label="Following"
              className="friendsTabs"
              sx={{
                color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                minHeight: "36px",
              }}
            />
            <Tab
              label="Groups"
              className="friendsTabs"
              sx={{
                color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                minHeight: "36px",
              }}
            />
            <Tab
              label="Add Friend"
              className="friendsTabs"
              sx={{
                color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                minHeight: "36px",
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UsersData value={value} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UsersData value={value} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ListOfGroups />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AddFriends />
        </TabPanel>
      </Box>
    </div>
  );
};

export default FriendsTabs;
