import React, { useState, useEffect, Fragment } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../../Styles/CreateChallengeStyles/Sidemenustyles/CenterdedTabs.css";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Notifications from "../NotificationsComp/Notifications";
import CreateChallenge from "../../pages/CreateChallenge";
import axios from "axios";
import baseurl from "../../Config";
import AvailableChallenges from "../../pages/AvailableChallenges";
import MyChallenges from "../../pages/MyChallenges";
import Leaderboard from "../../pages/Leaderboard";
import FriendsModule from "../../pages/Friends";
import environment from "../../Environments/Environments.js";
import { Button, Drawer, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../Redux/Actions";
import loader from "../../pages/loader";
import { SocketCommands } from "../../Utilis/Websocket";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="tabpanel"
    >
      {value === index && (
        <Box sx={{ height: "100%" }} p={0}>
          <div style={{ height: "100%" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  // selector value pass as 1st param
  // declare dispatch here and write a callback function
  const accesskey = localStorage.getItem("accesskey");
  const dispatch = useDispatch();
  SocketCommands();
  const navigate = useNavigate();
  const [accesstoken, setaccesstoken] = useState("");
  const [value, setValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  // const [anchorEln, setAnchorEln] = useState(true);
  const [initialStakeValue, setInitialStakeValue] = useState({});

  const sortbyorder = (a, b) => {
    return a.order - b.order;
  };

  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}games-detail/sports_detail/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        const initialeventdata =
          response.data.data.data.data.sport !== ({} || [] || "")
            ? Object.values(response && response.data.data.data.data.sport)
            : "";
        if (initialeventdata && initialeventdata.length > 0) {
          var initialname =
            initialeventdata && Object.values(initialeventdata[0].region);
          var firstCompetition = initialname[0].competition; // get the competition object inside the first object
          // firstCompetition.sort(sortbyorder)
          var sortedcomp = Object.values(firstCompetition).sort(sortbyorder);
          var firstCompetitionId = sortedcomp[0].id; // get the first key (id) of the first competition object
          var firstCompetitionName = sortedcomp[0].name;
          dispatch(
            Actions.senduserseldata({
              sportid: 1,
              sportname: "Football",
              regionid: 10001,
              leaguename: firstCompetitionName,
              competitionid: firstCompetitionId,
            })
          );
        } else {
          var firstCompetitionName = "";
          var firstCompetitionId = "";
          dispatch(Actions.senduserseldata([]));
        }
        if (initialeventdata && initialeventdata.length > 0) {
          dispatch(
            Actions.wssportdata(
              (response && response.data.data.data.data.sport) || ""
            )
          );
        } else {
          dispatch(Actions.wssportdata([]));
        }
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("accesskey")) {
  //     navigate("/login");
  //   }
  // }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const openDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  const buttonsx = {
    color: "#00000099",
    "&:hover": {
      color: "black",
    },
  };

  useEffect(() => {
    axios
      .get(`${baseurl.baseurl}constants/get_constant/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setInitialStakeValue(response.data.results[0]);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, []);

  return (
    <Fragment>
      <Box sx={{ width: "100%", height: "36px", alignContent: "center" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "white",
          }}
          className="centered-tabs-box"
        >
          <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="basic tabs example"
              sx={{
                minHeight: "36px",
                display: "flex",
                alignItems: "center",
              }}
              TabIndicatorProps={{ sx: { bgcolor: "#EC1087" } }}
              // sx={{"& button:hover": { borderBottom: "2px solid #EC1087", },}}
            >
              <Tab
                label="Available Challenges"
                className="centeredTab"
                {...a11yProps(0)}
                sx={{ minHeight: "36px" }}
              />
              <Tab
                label="Create Challenge"
                className="centeredTab"
                {...a11yProps(1)}
                sx={{ minHeight: "36px" }}
              />
              <Tab
                label="Leaderboard"
                className="centeredTab"
                {...a11yProps(2)}
                sx={{ minHeight: "36px" }}
              />
              <Tab
                label="Friends"
                className="centeredTab"
                {...a11yProps(3)}
                sx={{ minHeight: "36px" }}
              />
              <Tab
                label="My Challenges"
                className="centeredTab"
                {...a11yProps(4)}
                sx={{ minHeight: "36px" }}
              />
            </Tabs>
          </Box>
          {/* <Grid
            container
            sx={{ width: { xs: "35%", sm: "20%", md: "15%", lg: "10%" } }}
          >
          </Grid> */}
        </Box>
        <TabPanel value={value} index={0}>
          <AvailableChallenges />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CreateChallenge
            value={value}
            initialStakeValue={initialStakeValue}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Leaderboard />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <FriendsModule />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <MyChallenges />
        </TabPanel>
      </Box>
    </Fragment>
  );
}
