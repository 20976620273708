import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { WebSocketConnection } from "../../../../Utilis/Websocket";
import "../../../../Styles/CreateChallengeStyles/Matchdatacontainerstyles/Marketsfromgames.css";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../../../Redux/Actions";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// var websocket = socket.webfucntion();

const Marketsfromgames = (props) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const marketsrid = Math.random().toString(36).substring(2, 7);
  var CryptoJS = require("crypto-js");
  const initialgroupid = props.initialgroupid;
  const marketgroupid = props.marketgroupid;
  const gamedata = props.gamedata;
  const betslipdataselector = useSelector((state) => state.betslipmatchdata);
  const [marketdata, updatemarketdata] = useState();
  const [betslipdata, updatebetslipdata] = useState(betslipdataselector);
  const Access_Token = localStorage.getItem("accesskey");
  const dispatch = useDispatch();
  const gamedetails = useSelector((state) => state.sidemenudata);
  const showBetSlip = useSelector((state) => state.showBetSlip);
  const setShowBetSlip = useSelector((state) => state.setShowBetSlip);
  const [latestodds, getlatestodds] = useState(false);
  const mviewcmdcall = useSelector((state) => state.mviewcmdcall);
  const restrictedmarkets = useSelector((state) => state.restrictedmarkets);

  useEffect(() => {
    updatebetslipdata(betslipdataselector);
  }, [betslipdataselector]);

  React.useEffect(() => {}, [showBetSlip]);

  const matchseldata = {
    command: "get",
    params: {
      source: "betting",
      what: {
        market: ["type", "name", "group_name", "group_id", "order", "id"],
        event: ["id", "name", "price", "order"],
      },
      where: {
        game: {
          id: {
            "@in": [gamedata.gameid],
          },
        },
        market: {
          type: {
            "@in": restrictedmarkets,
          },
          group_id: {
            "@in": [marketgroupid],
          },
        },
      },
      subscribe: true,
    },
    rid: marketsrid,
  };

  const websocket = new WebSocketConnection();
  useEffect(() => {
    (async () => {
      var response = await websocket.sendcommand(matchseldata);
      getlatestodds(true);
      if (response.rid === marketsrid) {
        const marketsdata =
          response &&
          response.data &&
          response.data.data &&
          response.data.data.market &&
          response.data.data.market;
        var Arofmarkets = Object.values(marketsdata);
        updatemarketdata(Arofmarkets);
      }
    })();
  }, [marketgroupid]);

  function compareorder(a, b) {
    return a.order - b.order;
  }
  const sortedmarkets = marketdata && marketdata.sort(compareorder);
  const updatePageData = (pageData) => {
    const { eventId, eventName, evenPrice, marketName, marketid } = pageData;
    eventId &&
      eventName &&
      evenPrice &&
      marketName &&
      marketid &&
      updatebetslipdata((prev) => {
        var output = [...prev, pageData];
        if (prev && prev.map((m) => m.eventId).includes(pageData.eventId)) {
          output = prev.filter((el) => el.eventId !== pageData.eventId);
        }
        return output;
      });
    var initialprice = [];
    betslipdata.map((data) => {
      !initialprice.includes(data.evenPrice) &&
        initialprice.push(data.evenPrice);
    });
    localStorage.setItem(
      "gamedetails",
      CryptoJS.AES.encrypt(
        JSON.stringify(gamedetails),
        "dt-crg-ggp2p"
      ).toString()
    );
    dispatch(Actions.setShowBetSlip(true));
    // betslipdata && dispatch(Actions.showBetSlip(true));
  };

  //dispatch the user selected data to the redux store
  // for the desktop and the tablet view
  React.useEffect(() => {
    if (!mobileView) {
      localStorage.setItem(
        "betslipdata",
        CryptoJS.AES.encrypt(
          JSON.stringify(betslipdata),
          "dt-crg-ggp2p"
        ).toString()
      );
      betslipdata && dispatch(Actions.sendbetslipdata(betslipdata));
    }
  }, [betslipdata]);

  //socket calls for the mobile view temp test
  React.useEffect(() => {
    if (mobileView && latestodds) {
      setShowBetSlip && betslipdata && dispatch(Actions.showBetSlip(true));
      var eventprices = [];
      var eventids = [];
      var eventidprice =
        betslipdata &&
        betslipdata.map((data) => {
          !eventids.includes(data.eventId) && eventids.push(data.eventId);
          !eventprices.includes(data.eventprice) &&
            eventprices.push(data.eventPrice);
          return {
            eventid: data.eventId,
            eventprice: data.evenPrice,
          };
        });
      const eventrid = Math.random().toString(36).substring(2, 7);
      var getoddprice = {
        command: "get",
        params: {
          source: "betting",
          what: {
            event: ["id", "price", "name"],
          },
          where: {
            game: {
              type: {
                "@in": [0, 2],
              },
            },
            event: {
              id: {
                "@in": eventids,
              },
            },
          },
          subscribe: true,
        },
        rid: eventrid,
      };

      const websocket = new WebSocketConnection();
      (async () => {
        var eventresponse = await websocket.sendcommand(getoddprice);
        const dataobj = {
          eventdata: eventresponse,
        };
        dispatch(Actions.wssporteventdata(dataobj));
        localStorage.setItem(
          "betslipdata",
          CryptoJS.AES.encrypt(
            JSON.stringify(betslipdata),
            "dt-crg-ggp2p"
          ).toString()
        );
        betslipdata && dispatch(Actions.sendbetslipdata(betslipdata));
      })();
    }
  }, [betslipdata]);

  return (
    <React.Fragment>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ background: "#0000001A", marginTop: 3, borderRadius: 5 }}
        >
          <div className="marketHeading">Markets</div>
        </AccordionSummary>
        {marketdata ? (
          <AccordionDetails sx={{ padding: "0px" }}>
            <div className="Match-result-market">
              <div className="Accordion-details">
                {marketdata &&
                  marketdata.map((market, id) => {
                    return (
                      <Accordion
                        defaultExpanded={true}
                        key={id}
                        className="market-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ background: "#0000000D", marginTop: 2 }}
                        >
                          <div className="market-title-bc">{market.name}</div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion-market-details">
                          {market &&
                            market.event &&
                            Object.values(market.event)
                              .sort(compareorder)
                              .map((event, i) => {
                                const events = Object.keys(market.event);
                                return (
                                  <div
                                    className="market-group-holder-bc"
                                    style={{
                                      flex:
                                        events.length === 3
                                          ? "0 0 33.33%"
                                          : "0 0 50%",
                                    }}
                                    key={i}
                                  >
                                    <div
                                      className={
                                        betslipdataselector &&
                                        betslipdataselector.length > 0 &&
                                        betslipdataselector
                                          .map((x) => x.eventId)
                                          .includes(event.id)
                                          ? "market-group-item-bc active"
                                          : "market-group-item-bc"
                                      }
                                      onClick={(e) => {
                                        updatePageData({
                                          gameid: gamedata.gameid,
                                          team1: gamedata.team1,
                                          team2: gamedata.team2,
                                          matchtime: gamedata.time,
                                          matchdate: gamedata.date,
                                          eventId: event.id,
                                          eventName: event.name,
                                          evenPrice: event.price,
                                          marketName: market.name,
                                          marketid: market.id,
                                        });
                                      }}
                                    >
                                      <div className="market-name-bc">
                                        {event.name}
                                      </div>
                                      <div
                                        className={
                                          betslipdataselector &&
                                          betslipdataselector.length > 0 &&
                                          betslipdataselector
                                            .map((x) => x.eventId)
                                            .includes(event.id)
                                            ? "market-odd-bc active"
                                            : "market-odd-bc"
                                        }
                                      >
                                        {event.price}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <Stack spacing={2} style={{ padding: 10 }}>
            <Skeleton variant="rounded" width={"100%"} height={35} />
            <Skeleton variant="rounded" width={"100%"} height={35} />
            <Skeleton variant="rounded" width={"100%"} height={35} />
            <Skeleton variant="rounded" width={"100%"} height={35} />
          </Stack>
        )}
      </Accordion>
    </React.Fragment>
  );
};

export default Marketsfromgames;
