import React from "react";
import AvailableChallengesList from "../components/AvailableChallengesComp/AvailableChallengesList";
import * as Actions from "../Redux/Actions";
import { useDispatch } from "react-redux";
import baseurl from "../Config";
import axios from "axios";
import toast from "react-hot-toast";
import { SocketCommands } from "../Utilis/Websocket.js";

const AvailableChallenges = () => {
  const accesskey = localStorage.getItem("accesskey");
  const dispatch = useDispatch();
  dispatch(Actions.showMatchData(true));
  // dispatch(Actions.senduserseldata(""))

  //sidemenu sports api calling here to avoid the late state update

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <AvailableChallengesList />
    </div>
  );
};

export default AvailableChallenges;
