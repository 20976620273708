import React from "react";
import "../../Styles/LeaderboardStyles/Leaderboard.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Leaderslist from "./Leaderboardsdata/Leaderslist";
import gold from "../../Assets/gold.png";
import silver from "../../Assets/silver.png";
import bronze from "../../Assets/bronze.png";
import { rgbToHex } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%", width: "100%", display: "flex" }}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "100%", display: "flex" }}>
          <div style={{ height: "100%", width: "100%", display: "flex" }}>
            {children}
          </div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LeaderboardTabs = () => {
  const [value, setValue] = React.useState(0);
  const [top3, settop3] = React.useState();

  const topleaders = (leaders) => {
    settop3(leaders);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="leaderBoard-tabs-wrapper">
      <Box sx={{ width: { xs: "100%", sm: "90%", md: "70%", lg: "70%" } }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "rgba(0,0,0,.15)",
            borderRadius: "4px",
          }}
          className="leaderboard-tabs-box"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ sx: { bgcolor: "#EC1087" } }}
            sx={{
              minHeight: "36px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tab
              label="Month"
              {...a11yProps(0)}
              sx={{
                color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                minHeight: "36px",
              }}
              className="leaderboardTabs"
            />
            <Tab
              label="Week"
              {...a11yProps(1)}
              sx={{
                color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                minHeight: "36px",
              }}
              className="leaderboardTabs"
            />
            <Tab
              label="Day"
              {...a11yProps(2)}
              sx={{
                color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                minHeight: "36px",
              }}
              className="leaderboardTabs"
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Leaderslist value={value} gettopleaders={topleaders} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Leaderslist value={value} gettopleaders={topleaders} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Leaderslist value={value} gettopleaders={topleaders} />
        </TabPanel>
      </Box>
    </div>
  );
};

export default LeaderboardTabs;
