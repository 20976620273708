// import { sliderClasses } from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider from "react-animated-slider";
import { Grid, Box } from "@mui/material";
import "../../Styles/CreateChallengeStyles/CarouselStyles.css";
import axios from "axios";
import baseurl from "../../Config";

const Carousel = (props) => {
  return (
    <>
      {props.content && (
        <Slider autoplay={1000} style={{ width: "95%" }}>
          {props.content.map((item, index) => (
            <Grid
              item
              key={index}
              className="caraousel-container"
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ paddingTop: "1%" }}
            >
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  className="slider-image"
                  style={{ height: "inherit", width: "inherit" }}
                >
                  <img
                    src={item}
                    width="100%"
                    height="auto"
                    style={{ borderRadius: "4px", height: "inherit" }}
                    alt={`Slider ${index + 1}`}
                  />
                  <div
                    className="page-number"
                    style={{
                      position: "absolute",
                      bottom: "6%",
                      right: "1%",
                      background: "#000000bd",
                      color: "white",
                      width: "3vw",
                      height: "18px",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      borderRadius: "10px",
                      fontSize: "10px",
                      alignItems: "center",
                    }}
                  >
                    {index + 1} / {props.content.length}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Slider>
      )}
    </>
  );
};

export default Carousel;
