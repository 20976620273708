import React, { useState } from "react";
import { Paper, TextField, InputAdornment } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import axios from "axios";
import baseurl from "../../../Config";
import toast from "react-hot-toast";
import "../../../Styles/FriendsModule/FriendsModule.css";
import Divider from "@mui/material/Divider";

const GroupMembers = (props) => {
  const accesskey = localStorage.getItem("accesskey");
  const [groupuserlist, setgroupuserlist] = useState("");
  const [removeuser, confirmremoveuser] = useState(false);
  const [searcheduser, setsearchUsers] = useState("");
  const [responsecount, setresponsecount] = useState("");
  const [page, setPage] = React.useState(1);
  const count = Math.ceil(responsecount / 10);
  const [removeIds, setRemoveIds] = React.useState([]);

  //fetch all the group members list
  React.useEffect(() => {
    axios
      .get(
        `${baseurl.baseurl}p2p-group/${props.groupid}/get-group-members/?page=${page}&search=${searcheduser}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(accesskey)}`,
          },
        }
      )
      .then((response) => {
        setresponsecount(response.data.count);
        setgroupuserlist(response.data.results);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [page, searcheduser, responsecount, removeuser]);

  const handlepageChange = (event, value) => {
    setPage(value);
  };

  const handleremoveUser = (id, action) => {
    if (action === "remove") {
      confirmremoveuser(true);
      setRemoveIds((prevVal) => {
        return [...prevVal, id];
      });
    } else {
      const cancelIds = removeIds.filter((remId) => remId !== id);
      setRemoveIds(cancelIds);
    }
  };

  //edit group
  const handleeditgroup = () => {
    axios
      .delete(`${baseurl.baseurl}remove-members/`, {
        data: {
          group_id: props.groupid,
          authuser_id: removeIds,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        toast.success("Changes has been updated", { duration: 1000 });
        const updatedGroupMemberList = groupuserlist.filter(
          (user) => !removeIds.includes(user.id)
        );
        if (page > 1 && updatedGroupMemberList.length === 0) {
          setPage(page - 1);
        }
        confirmremoveuser(false);
      })
      .catch((err) => toast.error(err, { duration: 1000 }));
  };

  return (
    <React.Fragment>
      <>
        {groupuserlist ? (
          <div className="add-friend-container">
            <div className="group-name">Group : {props.editgroupname}</div>
            <div className="search-container">
              <div className="textfield-container" style={{ margin: "0 10px" }}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  placeholder="Search by User Name"
                  value={searcheduser}
                  onChange={(e) => setsearchUsers(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="search-icon" />
                      </InputAdornment>
                    ),
                    style: {
                      fontSize: "14px",
                      textAlign: "center",
                      color: "rgb(0,0,0,0.8)",
                      background: "rgba(0,0,0,0.1)",
                      fontWeight: 600,
                    },
                  }}
                  inputProps={{ maxLength: 32 }}
                />
              </div>
            </div>
            <Divider />
            {groupuserlist.length > 0 ? (
              <div>
                <div style={{ height: "50vh", overflowY: "overlay" }}>
                  <TableContainer component={Paper} style={{ width: "100%" }}>
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow
                          style={{
                            borderBottom: "1px solid #000000",
                            background: "#f2f2f2",
                          }}
                        >
                          <TableCell className="table-heading" align="center">
                            User
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            User Name
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            User number
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupuserlist.map((users, index) => (
                          <TableRow
                            key={index}
                            style={{
                              borderBottom: "1px solid #0000001f",
                              background: "#f2f2f2",
                            }}
                          >
                            <TableCell className="table-data" align="center">
                              <Avatar
                                alt={users.username}
                                src="/static/images/avatar/1.jpg"
                                sx={{ height: 28, width: 28, margin: "auto" }}
                              />
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {users.p2p_user_id}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {users.p2p_user_id}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {!removeIds.includes(users.id) ? (
                                <Button
                                  className="btn"
                                  size="small"
                                  variant="contained"
                                  onClick={(e) =>
                                    handleremoveUser(users.id, "remove")
                                  }
                                  style={{ background: "#1c226b" }}
                                >
                                  remove
                                </Button>
                              ) : (
                                <Button
                                  className="btn"
                                  size="small"
                                  variant="contained"
                                  onClick={(e) =>
                                    handleremoveUser(users.id, "cancel")
                                  }
                                  style={{ background: "#1c226b" }}
                                >
                                  cancel
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      padding: "10px",
                      background: "#f2f2f2",
                    }}
                  >
                    <div>
                      <Pagination
                        count={count}
                        page={page}
                        onChange={handlepageChange}
                        size="small"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      className="confirmbtn"
                      size="small"
                      variant="contained"
                      onClick={(e) =>
                        removeuser === true ? handleeditgroup() : ""
                      }
                      style={{
                        background: removeuser === true ? "#1c226b" : "#dfd5d5",
                        margin: 10,
                      }}
                    >
                      confirm
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px",
                  fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                  fontSize: "16px",
                }}
              >
                <div>No results found</div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
          </div>
        )}
      </>
    </React.Fragment>
  );
};

export default GroupMembers;
