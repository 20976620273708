import React from "react";
import { Paper, Button, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import baseurl from "../../Config";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import * as socket from "../../Utilis/Websocket";
import * as Actions from "../../Redux/Actions";
import { useSelector, useDispatch } from "react-redux";
import "../../Styles/LeaderboardStyles/Leaderboard.css";
import toast from "react-hot-toast";
// import { WebSocketConnection, SocketConnection } from "../../Utilis/Websocket";
import { useEffect } from "react";

const ListofToJoin = () => {
  const dispatch = useDispatch();
  // const websocket = new WebSocketConnection();
  const [page, setPage] = React.useState(1);
  const [challengeslist, setchallengeslist] = React.useState();
  const [responsecount, setresponsecount] = React.useState("");
  const count = Math.ceil(responsecount / 10);
  const otherHeights = 88;
  let tableHeight = window.innerHeight - otherHeights;
  const handleChange = (event, value) => {
    setPage(value);
  };

  // get initial restricted market types
  useEffect(() => {
    const data = {
      sport_id: 1,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };

    axios
      .post(`${baseurl.baseurl}games-detail/`, { ...data }, { headers })
      .then(function (response) {
        const array = response && response.data.data;
        dispatch(Actions.restrictedmarkets(array));
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, []);

  //Active challenges list
  const accesskey = localStorage.getItem("accesskey");
  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}active-challenges/?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setresponsecount(response.data.count);
        setchallengeslist(response.data.results);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [page, responsecount]);

  //Accept challenge
  const handleaction = (challengeid, name) => {
    const data = {
      challenge_id: challengeid,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };

    axios
      .post(`${baseurl.baseurl}challenge/${name}/`, data, { headers })
      .then(function (response) {
        const removechallenge = challengeslist.filter(
          (event) => event.challenge.challenge_id !== challengeid
        );
        setchallengeslist(removechallenge);
        if (page > 1 && removechallenge.length === 0) {
          setPage(page - 1);
        }
        setresponsecount(removechallenge.length);
        toast.success(response.data.message, { duration: 1000 });
      })
      .catch((err) =>
        toast.error("something went wrong, check balance and try again", {
          duration: 1000,
        })
      );
  };

  return (
    <div className="challenges-container" style={{ height: "100%" }}>
      <div className="challenges-wrapper" style={{ maxHeight: "100%" }}>
        {challengeslist ? (
          <div style={{ height: "inherit" }}>
            {challengeslist.length > 0 ? (
              <>
                <TableContainer
                  component={Paper}
                  style={{
                    width: "100%",
                    maxHeight: tableHeight + "px",
                    overflowY: "overlay",
                  }}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className="tableheaders">
                        <TableCell className="table-heading" align="center">
                          Date
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Time
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Sport
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Match
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Market Name
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Event Name
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Type
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Status
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Amount
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Challenger
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {challengeslist &&
                        challengeslist.map((challenge, index) => (
                          <TableRow key={index} className="tableRowdetails">
                            <TableCell className="table-data" align="center">
                              {
                                challenge.challenge.bet_slip_challenge[0]
                                  .game_date
                              }
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {
                                challenge.challenge.bet_slip_challenge[0]
                                  .game_time
                              }
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {
                                challenge.challenge.bet_slip_challenge[0]
                                  .sport_name
                              }
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {
                                challenge.challenge.bet_slip_challenge[0]
                                  .game_name
                              }
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {
                                challenge.challenge.bet_slip_challenge[0]
                                  .market_name
                              }
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {
                                challenge.challenge.bet_slip_challenge[0]
                                  .event_name
                              }
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {challenge.challenge.type_of_challenge}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {challenge.status_of_challenge.status_type}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {challenge.challenge.user_stack_amount} FRw
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {challenge.challenge.created_by.p2p_user_id}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>
                                  <Button
                                    size="small"
                                    className="btn"
                                    style={{ color: "white" }}
                                    onClick={(e) =>
                                      handleaction(
                                        challenge.challenge.challenge_id,
                                        "accept"
                                      )
                                    }
                                  >
                                    Accept
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    size="small"
                                    className="btn"
                                    style={{ color: "white" }}
                                    onClick={(e) =>
                                      handleaction(
                                        challenge.challenge.challenge_id,
                                        "decline"
                                      )
                                    }
                                  >
                                    Decline
                                  </Button>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="mychallengepagination">
                  <Pagination
                    count={count}
                    page={page}
                    onChange={handleChange}
                    size="small"
                  />
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  fontSize: "16px",
                  fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                }}
              >
                <div>No results found</div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListofToJoin;
