import CenteredTabs from "./components/CenteredTabsComp/CenteredTabs";
// import Login from "./pages/LoginTest";
import { Provider } from "react-redux";
import { createStore } from "redux";
import Reducer from "./Redux/Reducers";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Pagenotfound from "./Assets/errorImage1.jpg";
import { SocketConnection } from "./Utilis/Websocket";
// import { StylesProvider } from '@mui/styles';
import { ErrorBoundary } from "react-error-boundary";
import "./App.css";
import Loader from "./pages/loader";
import React, { useEffect, useState } from "react";
import { Testusers } from "./pages/TestUsers";
import toast from "react-hot-toast";
import axios from "axios";
import baseurl from "./Config";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/material";
import logo from "./Assets/logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import { split } from "lodash";
import LaunchingPage from "./pages/LandingPage";

const store = createStore(Reducer);
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        flexDirection: "column",
        width: "50%",
        height: "auto",
        marginTop: "40px",
        gap: "10px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "60%",
          border: "tranparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={Pagenotfound}
          width="70%"
          height="70%"
          alt="img"
          object-fit="cover"
        />
      </div>
      <div style={{ font: "20px Roboto,Arial,Helvetica,sans-serif" }}>
        Something went wrong, please try again
      </div>
      <button
        onClick={resetErrorBoundary}
        style={{
          background: "#1c226b",
          color: "#ffffff",
          padding: "5px",
          borderRadius: "5px",
          font: "10px Roboto,Arial,Helvetica,sans-serif",
          cursor: "pointer",
        }}
      >
        Refresh
      </button>
    </div>
  );
}

function App() {
  const [connected, setconnected] = useState("");
  const [open, setOpen] = useState(true);
  const [accesstoken, setaccesstoken] = useState("");
  var CryptoJS = require("crypto-js");
  const cb = (data) => {
    setconnected(data);
  };
  useEffect(() => {
    SocketConnection(cb);
  }, []);

  const readystate = localStorage.getItem("Readystate");

  //iframe integration check
  const iframeurl = document.location.href;
  const substring = iframeurl.split("?") ? iframeurl.split("?") : "";
  const spliturl = substring[1] && substring[1].split("&");
  const paramsobj =
    spliturl &&
    spliturl.reduce((acc, curr) => {
      const [key, value] = curr.split("=");
      acc[key] = value;
      return acc;
    }, {});

  //send the user detils to the database
  React.useEffect(() => {
    const data = {
      username: paramsobj && paramsobj.username,
      userid: paramsobj && paramsobj.user_id,
      user_name:
        paramsobj &&
        paramsobj.firstname !== "undefined" &&
        paramsobj &&
        paramsobj.lasttname !== "undefined"
          ? `${paramsobj.lasttname} ${paramsobj.lasttname}`
          : "",
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${baseurl.baseurl}p2p-user-login/`, { ...data }, { headers })
      .then(function (response) {
        var accesskey = response.data.access_token;
        setaccesstoken(accesskey);
        const userdetails = {
          username: response.data.username,
          userid: data.userid,
          auth_token: paramsobj && paramsobj.access_token,
        };
        localStorage.setItem("accesskey", JSON.stringify(accesskey));
        localStorage.setItem(
          "userdetails",
          CryptoJS.AES.encrypt(
            JSON.stringify(userdetails),
            "dt-crg-ggp2p"
          ).toString()
        );
        setOpen(false);
      })
      .catch(function (error) {});
  }, [iframeurl]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <div className="App">
        <BrowserRouter>
          <Toaster position="top-right" reverseOrder={false} />
          <Provider store={store}>
            {open && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  background: "#d9d9d9",
                }}
                open={open}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <img
                    src={logo}
                    alt="logoimage"
                    className="logoimage"
                    style={{ width: "20vh", height: "20vh" }}
                  ></img>
                  <CircularProgress
                    size={"4rem"}
                    style={{
                      color: "#ec1087",
                      margin: "auto",
                    }}
                  />
                  <span
                    style={{
                      color: "#ec1087",
                      fontSize: "10px",
                      display: "flex",
                      justifyContent: "center",
                      margin: "5px",
                    }}
                  >
                    please wait, loading...
                  </span>
                </Box>
              </Backdrop>
            )}
            {connected && !open && (
              <div>
                <CenteredTabs />
              </div>
            )}
          </Provider>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

export default App;
