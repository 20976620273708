import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  InputAdornment,
  Divider,
  Grid,
} from "@mui/material";
import toast from "react-hot-toast";
import axios from "axios";
import { useDispatch } from "react-redux";
import * as Actions from "../../../../Redux/Actions";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import baseurl from "../../../../Config";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TableHead from "@mui/material/TableHead";
import "../../../../Styles/ChallengemodeStyles/ChallengeMode.css";
import CircularProgress from "@mui/material/CircularProgress";
import { duration } from "moment/moment";

const textfieldstyle = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: 1.5,
      borderColor: "#ec1087",
    },
    "&:hover fieldset": {
      border: 1.5,
      borderColor: "#ec1087",
    },
  },
};

const AddFriend = (props) => {
  const [userData, setUserData] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [responsecount, setresponsecount] = useState();
  const [loader, setLoader] = useState(false);
  const count = Math.ceil(responsecount / 10);
  const [page, setPage] = React.useState(1);
  const [issearchinguser, setissearchinguser] = useState(false);
  const dispatch = useDispatch();
  const [searchnewuser, setsearchnewuser] = useState(false);
  const handlepageChange = (event, value) => {
    setPage(value);
  };

  const accesskey = localStorage.getItem("accesskey");

  //fetching all usersdata list
  // useEffect(() => {
  //   axios
  //     .get(`${baseurl.baseurl}get-p2p-user/?page=${page}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${JSON.parse(accesskey)}`,
  //       },
  //     })
  //     .then((response) => {
  //       setLoader(false);
  //       setUserData(response.data.results);
  //       setresponsecount(response.data.count);
  //     })
  //     .catch((err) => {});
  // }, [page, responsecount, searchUser]);

  // useEffect(() => {
  //   handleSearch();
  // }, [searchUser.length == 0]);

  const handleSearch = () => {
    setLoader(true);
    axios
      .get(`${baseurl.baseurl}get-p2p-user/?search=${searchUser}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        // setissearchinguser(true);
        // setresponsecount(response.data.count);
        setUserData(response.data.results);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err.response.data.detail);
      });
    setsearchnewuser(true);
  };
  const handleAddUser = (id, name, user_number, searchuser) => {
    dispatch(Actions.addUserData({ id: id, name: name }));
    dispatch(
      Actions.selectedUserData({
        id: id,
        name: name,
        usernumber: user_number,
        type: searchuser,
      })
    );
    dispatch(Actions.isPrivateChallenge(true));
    props.adduser(0);
  };
  // POST request for adding new friend
  const selectNewFriend = (id) => {
    const data = {
      p2p_user: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };
    axios
      .post(`${baseurl.baseurl}p2p-user/add-friend/`, { ...data }, { headers })
      .then(function (response) {
        toast.success(response.data.message, { duration: 1000 });
        const removeuserfromlist = userData.filter(
          (user) => user.p2p_user_id !== id
        );
        if (page > 1 && removeuserfromlist.length === 0) {
          setPage(page - 1);
        }
        setUserData(removeuserfromlist);
        // setresponsecount();
        // props.userdata(true);
      })
      .catch((err) => {
        toast.error("something went wrong please refresh");
      });
  };
  //search users list based on mobile number
  // const searchUsers = (searchValue) => {
  //     setSearchUser(searchValue);
  //     if (searchUser !== "") {
  //         const filteredData = userData.filter((user) =>
  //             user?.p2p_user_id.toString().includes(searchUser.toLowerCase())
  //         );
  //         setFilteredResults(filteredData);
  //     }
  // };

  return (
    <React.Fragment>
      <>
        <div className="add friend">
          <div className="textfield-container" style={{ gap: "16px" }}>
            <TextField
              type="number"
              id="outlined-basic"
              size="small"
              placeholder="Search by number"
              style={{ width: "70%" }}
              value={searchUser}
              onChange={(e) => {
                // setLoader(true);
                // setissearchinguser(false);
                setSearchUser(e.target.value);
                setsearchnewuser(false);
              }}
              autoComplete="off"
              sx={textfieldstyle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {loader ? (
                      <CircularProgress size={20} sx={{ color: "#ec1087" }} />
                    ) : (
                      <SearchIcon className="search-icon" />
                    )}
                  </InputAdornment>
                ),
                style: {
                  fontSize: "14px",
                  textAlign: "center",
                  color: "rgb(0,0,0,0.8)",
                  background: "#fff",
                },
              }}
              inputProps={{ maxLength: 32 }}
            />
            <div>
              <Button
                disabled={searchUser.length == 12 ? false : true}
                className="searchBtn"
                size="small"
                variant="contained"
                onClick={handleSearch}
                style={{
                  background: searchUser.length === 12 ? "#1c226b" : "#dfd5d5",
                }}
              >
                search
              </Button>
            </div>
          </div>
          <div
            style={{
              background: "#f2f2f2",
              maxHeight: "60vh",
              overflowY: "overlay",
              height: "50vh",
            }}
          >
            {userData && userData.length == 1 ? (
              <>
                <TableContainer
                  style={{
                    maxHeight: "60vh",
                    overflowY: "overlay",
                    height: "50vh",
                  }}
                >
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow
                        style={{
                          borderBottom: "1px solid #000000",
                          background: "#f2f2f2",
                        }}
                      >
                        <TableCell className="table-heading" align="center">
                          User
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          User Name
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          User number
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData &&
                        userData.map((user, id) => {
                          return (
                            <TableRow
                              key={id}
                              style={{
                                borderBottom: "1px solid #0000001f",
                                background: "#f2f2f2",
                              }}
                            >
                              <TableCell className="table-data" align="center">
                                <Avatar
                                  alt={user.username}
                                  src="/static/images/avatar/1.jpg"
                                  sx={{
                                    height: 28,
                                    width: 28,
                                    margin: "auto",
                                  }}
                                />
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                {user.p2p_user_id}
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                {user.p2p_user_id}
                              </TableCell>
                              <TableCell align="center" display="flex" gap="2">
                                <Grid
                                  container
                                  spacing={1}
                                  justifyContent="center"
                                >
                                  <Grid item>
                                    <Button
                                      className="btn"
                                      size="small"
                                      variant="contained"
                                      onClick={(e) =>
                                        handleAddUser(
                                          user.id,
                                          user.username,
                                          user.p2p_user_id,
                                          "searchuser"
                                        )
                                      }
                                    >
                                      select
                                    </Button>
                                  </Grid>
                                  {!user.following && (
                                    <Grid item>
                                      <Button
                                        className="btn"
                                        size="small"
                                        variant="contained"
                                        onClick={(e) =>
                                          selectNewFriend(user.p2p_user_id)
                                        }
                                      >
                                        + Add Friend
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="pagination">
                  <Pagination
                    // count={count}
                    page={page}
                    onChange={handlepageChange}
                    size="small"
                    sx={{
                      "& button": {
                        fontSize: "12px",
                        fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              userData &&
              userData.length == 0 && (
                <div
                  style={{
                    fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    fontSize: "16px",
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  No results found
                </div>
              )
            )}
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

export default AddFriend;
