import React from "react";
import "../../Styles/MyChallengesStyles/MyChallenges.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ActiveChallenges from "./MyChallengesdataCont/Activechallenges";
import ListofWons from "./MyChallengesdataCont/ListofWon";
import ListofLosts from "../MyChallengesComp/MyChallengesdataCont/ListofLosts";
import ExpiredChallenges from "./MyChallengesdataCont/ExpiredChallenges";
// import TakenChallenges from "./MyChallengesdataCont/TakenChallenges";
import CreatedChallenges from "./MyChallengesdataCont/CreatedChallenges";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "inherit" }}>
          <div style={{ height: "inherit" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyChallengesTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="myChallenge-tabs-wrapper">
      <Box sx={{ width: { xs: "100%", sm: "95%", md: "90%", lg: "80%" } }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="myChallenges-tabs-box"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="basic tabs example"
            // TabIndicatorProps={{ sx: { bgcolor: "#EC1087" }}}
            sx={{
              minHeight: "36px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tab
              label="Created"
              className="myChallengeTab"
              sx={{ minHeight: "36px" }}
              {...a11yProps(2)}
            />
            <Tab
              label="Active"
              className="myChallengeTab"
              sx={{ minHeight: "36px" }}
              {...a11yProps(1)}
            />
            <Tab
              label="Expired"
              className="myChallengeTab"
              sx={{ minHeight: "36px" }}
              {...a11yProps(2)}
            />
            <Tab
              label="Won"
              className="myChallengeTab"
              sx={{ minHeight: "36px" }}
              {...a11yProps(3)}
            />
            <Tab
              label="Lost"
              className="myChallengeTab"
              sx={{ minHeight: "36px" }}
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CreatedChallenges />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ActiveChallenges />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ExpiredChallenges />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ListofWons />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ListofLosts />
        </TabPanel>
      </Box>
    </div>
  );
};

export default MyChallengesTabs;
