import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import "../../../Styles/CreateChallengeStyles/Betslipstyles/Betslip.css";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import ChallengeModalTabs from "../ChallengeModelContainer/ChallengeModelTabs";
// import { ws } from "../../../Utilis/Websocket";
import { WebSocketConnection } from "../../../Utilis/Websocket";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../../Redux/Actions";
import baseurl from "../../../Config";
import Tooltip from "@mui/material/Tooltip";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import axios from "axios";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import { debounce, initial } from "lodash";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Co2Sharp } from "@mui/icons-material";

const BetSlip = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  var CryptoJS = require("crypto-js");
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const lfirststake = localStorage.getItem("firststake");
  const lsecondstake = localStorage.getItem("secondstake");
  const lthirdstake = localStorage.getItem("thirdstake");
  const userdetails = localStorage.getItem("userdetails");
  const accesskey = localStorage.getItem("accesskey");
  const betslipdata = localStorage.getItem("betslipdata");
  var bytes = userdetails && CryptoJS.AES.decrypt(userdetails, "dt-crg-ggp2p");
  var logindetails = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const initialeventdata = useSelector((state) => state.initialeventdata);
  const betslipdataselector = useSelector((state) => state.betslipmatchdata);
  const subscribedata = useSelector((state) => state.subscribedata);
  const [isloading, setisloading] = useState(true);
  const [open, setOpen] = useState(false);
  const [stakeAmount, setStakeAmount] = useState("");
  const [openChallenge, setOpenChallenge] = useState(false);
  const [emptyBetslip, setEmptyBetslip] = useState(false);
  const [betsliplist, setBetslipList] = useState(
    betslipdataselector ? betslipdataselector : []
  );
  const [matchData, updateMatchData] = useState([]);
  const [challengepossiblewin, setchallengepossiblewin] = useState(Number(0));
  const [placebetpossiblewin, setplacebetpossiblewin] = useState(Number(0));
  const [tax, gettax] = useState();
  const [finalpayout, setfinalpayout] = useState();
  const [minStake, setminstake] = useState(1000);
  const [odds, setodds] = useState("");
  const [edit, setEdit] = useState(true);
  const [isMouseDown, setIsMouseDown] = useState();
  const adminStakeData = props.initialStakeValue;
  const [favStakeSelection, setFavStakeSelection] = useState("0");
  const inputRef = useRef(null);
  const [isIconClicked, setIsIconClicked] = useState(false);
  const [firstStakeValue, setFirstStakeValue] = useState(() => {
    return lfirststake ? lfirststake : adminStakeData.firststake;
  });
  const [secondStakeValue, setSecondStakeValue] = useState(() => {
    return lsecondstake ? lsecondstake : adminStakeData.secondstake;
  });
  const [thirdStakeValue, setThirdStakeValue] = useState(() => {
    return lthirdstake ? lthirdstake : adminStakeData.thirdstake;
  });

  const [deletedEvent, setdeletedEvent] = useState(null);
  const [stakeError, setStakeError] = useState(false);
  const [balance, checkbalance] = useState(0);
  const [updatedodds, setupdatedodds] = useState();
  const [oddschange, updateoddschange] = useState(false);
  const requestid = Math.random().toString(36).substring(2, 7);
  const restoreloginrid = Math.random().toString(36).substring(2, 7);
  const eventrid = Math.random().toString(36).substring(2, 7);
  const wssporteventdata = useSelector((state) => state.wssporteventdata);
  var bytes = betslipdata && CryptoJS.AES.decrypt(betslipdata, "dt-crg-ggp2p");
  var decryptedBSData = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const isActive = useSelector((state) => state.isactive);
  var eventprices = [];
  var eventids = [];
  var eventidprice =
    decryptedBSData &&
    decryptedBSData.map((data) => {
      !eventids.includes(data.eventId) && eventids.push(data.eventId);
      !eventprices.includes(data.eventprice) &&
        eventprices.push(data.eventPrice);
      return {
        eventid: data.eventId,
        eventprice: data.evenPrice,
      };
    });

  //multiply the odds
  React.useEffect(() => {
    getnewvalue();
    if (betslipdataselector && betslipdataselector.length === 0) {
      setStakeAmount();
    }
    updateMatchData(betslipdataselector);
    const eventprice = [];
    betslipdataselector &&
      betslipdataselector.map((singlebet) => {
        eventprice.push(singlebet.evenPrice);
      });
    const multiply =
      eventprice &&
      eventprice.reduce((prevValue, curValue) => {
        return prevValue * curValue;
      }, 1);
    setodds(multiply && multiply.toFixed(2));
  }, [betslipdataselector, updatedodds]);

  var multipliedodds;
  //get updated events data from BC
  useEffect(() => {
    var oddsdata =
      wssporteventdata !== null &&
      wssporteventdata &&
      wssporteventdata.eventdata.data.data.event &&
      wssporteventdata.eventdata.data.data.event;
    var intialeventdataarray =
      oddsdata &&
      Object.keys(oddsdata).map((data) => {
        return oddsdata[data];
      });

    const eventprices =
      intialeventdataarray && intialeventdataarray.map((event) => event.price);
    multipliedodds =
      eventprices &&
      eventprices.reduce((prevValue, curValue) => {
        return prevValue * curValue;
      }, 1);

    // setodds(multiply && multiply.toFixed(2));
    setupdatedodds(intialeventdataarray);
    intialeventdataarray &&
      setdeletedEvent(
        betslipdataselector &&
          betslipdataselector.filter((item1) => {
            return intialeventdataarray.every((item2) => {
              return item1.eventId !== item2.id;
            });
          })
      );
  }, [wssporteventdata]);

  // const [deletedEventId, setdeletedEventId] = useState([...deletedEventId,])
  const deletedEventId = [];
  deletedEvent &&
    deletedEvent.forEach((ele) => {
      deletedEventId.push(ele.eventId);
    });

  const handleClickOpen = () => {
    setOpenChallenge(true);
  };

  const handleClose = () => {
    dispatch(Actions.showChallenger(true));
    dispatch(Actions.isPrivateChallenge(false));
    setOpenChallenge(false);
  };

  const onInputChange = (e) => {
    setisloading(true);
    const { value } = e.target;
    const re = /^[0-9]*$/;
    if (value === "" || re.test(value)) {
      setStakeAmount(value);
    }
  };

  const handleCloseBetslip = async (itemIndex, removeeventid) => {
    // const removeactiveevent = isActive.filter((eventid) => eventid !==removeeventid)

    const removeBetslip = matchData.filter(
      (event, index) => index !== itemIndex
    );
    if (removeBetslip.length === 0) {
      setStakeAmount();
    }
    updateMatchData(removeBetslip);

    dispatch(Actions.initialcompetition(false));
    removeBetslip && dispatch(Actions.sendbetslipdata(removeBetslip));
    localStorage.setItem(
      "betslipdata",
      CryptoJS.AES.encrypt(
        JSON.stringify(removeBetslip),
        "dt-crg-ggp2p"
      ).toString()
    );

    if (mobileView && removeBetslip.length >= 1) {
      // removeBetslip && dispatch(Actions.sendbetslipdata(removeBetslip));
      var eventprices = [];
      var eventids = [];
      var eventidprice =
        removeBetslip &&
        removeBetslip.map((data) => {
          !eventids.includes(data.eventId) && eventids.push(data.eventId);
          !eventprices.includes(data.eventprice) &&
            eventprices.push(data.eventPrice);
          return {
            eventid: data.eventId,
            eventprice: data.evenPrice,
          };
        });

      var getoddprice = {
        command: "get",
        params: {
          source: "betting",
          what: {
            event: ["id", "price", "name"],
          },
          where: {
            game: {
              type: {
                "@in": [0, 2],
              },
            },
            event: {
              id: {
                "@in": eventids,
              },
            },
          },
          subscribe: true,
        },
        rid: eventrid,
      };

      //socket call back
      const websocket = new WebSocketConnection();
      var eventresponse = await websocket.sendcommand(getoddprice);
      const dataobj = {
        eventdata: eventresponse,
      };
      dispatch(Actions.wssporteventdata(dataobj));
    }
  };

  //edit the stake amounts
  const handleEdit = () => {
    setIsIconClicked(!isIconClicked);
    firstItemEnable(!isIconClicked);
    if (
      firstStakeValue < 1000 ||
      secondStakeValue < 1000 ||
      thirdStakeValue < 1000
    ) {
      setEdit(edit);
      setStakeError(true);
    } else {
      setEdit(!edit);
      inputRef.current.focus();
      setStakeError(false);
    }
  };
  const firstItemEnable = (val) => {
    const fields = ["firstTextField", "secondTextField", "thirdTextField"].map(
      (field) => document.getElementById(field)
    );
    if (val) {
      fields[0].style.backgroundColor = "#fff";
      fields[0].style.border = "1px solid";
    } else {
      fields.forEach((field) => {
        field.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
        field.style.border = "none";
      });
    }
  };

  // handle stake fields
  const handleTextFieldChange = (field, otherFields, stakeValue) => {
    const currentField = document.getElementById(field);
    if (matchData.length > 0 && edit === true) {
      setStakeAmount(stakeValue);
    }
    if (isIconClicked === true) {
      otherFields.forEach((f) => {
        const otherField = document.getElementById(f);
        otherField.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
        otherField.style.border = "none";
      });
      currentField.focus();
      currentField.style.backgroundColor = "#fff";
      currentField.style.border = "1px solid";
    }
  };

  const handleFirstTextField = (e) => {
    handleTextFieldChange(
      "firstTextField",
      ["secondTextField", "thirdTextField"],
      firstStakeValue
    );
  };

  const handleSecondTextField = (e) => {
    handleTextFieldChange(
      "secondTextField",
      ["firstTextField", "thirdTextField"],
      secondStakeValue
    );
  };

  const handleThirdTextField = (e) => {
    handleTextFieldChange(
      "thirdTextField",
      ["firstTextField", "secondTextField"],
      thirdStakeValue
    );
  };

  const handleFirstStakeValue = (e) => {
    setFirstStakeValue(e.target.value);
    localStorage.setItem("firststake", e.target.value);
  };

  const handleSecondStakeValue = (e) => {
    setSecondStakeValue(e.target.value);
    localStorage.setItem("secondstake", e.target.value);
  };

  const handleThirdStakeValue = (e) => {
    setThirdStakeValue(e.target.value);
    localStorage.setItem("thirdstake", e.target.value);
  };

  const placebetdata =
    updatedodds &&
    updatedodds.map((ele) => {
      return {
        event_id: ele.id,
        price: ele.price,
      };
    });

  // mousedown functionality
  const handleMouseDown = (id) => {
    document.getElementById(id).style.backgroundColor = "rgb(236, 16, 135)";
    setIsMouseDown(id);
  };

  const handleMouseUp = (id) => {
    document.getElementById(id).style.backgroundColor = "#d9d9d9";
    setIsMouseDown(false);
  };

  //place a bet with BC
  const handleplacebet = async () => {
    dispatch(Actions.sendIsLoading(true));
    const websocket = new WebSocketConnection();
    //restore the session login
    var restore_login = {
      command: "restore_login",
      params: {
        user_id: logindetails.userid,
        auth_token: logindetails.auth_token,
      },
      rid: restoreloginrid,
    };

    //place a bet to BC
    var placebet = {
      command: "do_bet",
      params: {
        type: matchData.length > 1 ? 2 : 1,
        mode: 0,
        amount: stakeAmount,
        bets: placebetdata,
      },
      rid: requestid,
    };

    var sessionrestore = await websocket.sendcommand(restore_login);
    var response = await websocket.sendcommand(placebet);

    //clear the betslip once the bet is placed
    if (response.data.result === "OK") {
      dispatch(Actions.sendIsLoading(false));
      toast.success("Bet placed successfully", {
        id: "place a bet",
        duration: 1000,
      });
      dispatch(Actions.sendbetslipdata(""));
      localStorage.setItem("betslipdata", "");
      setStakeAmount("");
    } else {
      toast.error("can't place a bet at the moment, please try later");
    }
  };

  const debounce = (func) => {
    "use strict";
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  var oddschanged = false;

  //BC api call to get the lastest odd vlaues and compare
  const getnewvalue = (existingvalue) => {
    const eventprice = [];
    if (!existingvalue) {
      return "";
    }

    const newPrice =
      updatedodds &&
      updatedodds.find((eventdata) => eventdata.id === existingvalue.eventId);

    if (newPrice && newPrice?.price !== existingvalue.evenPrice) {
      oddschanged = true;
      const eventprice = [];
      updatedodds.map((singlebet) => {
        eventprice.push(singlebet.price);
      });
      multipliedodds =
        eventprice &&
        eventprice.reduce((prevValue, curValue) => {
          return (prevValue * curValue).toFixed(2);
        }, 1);
      return (
        <div>
          <div style={{ textDecoration: "line-through", color: "red" }}>
            {existingvalue.evenPrice}
          </div>
          <div>{newPrice && newPrice.price}</div>
        </div>
      );
    } else {
      return <div>{existingvalue.evenPrice}</div>;
    }
  };

  //get the tax details and finalpayout
  useEffect(() => {
    const possibleWin =
      2 * (stakeAmount - (stakeAmount * adminStakeData.GG_Commission) / 100);
    setchallengepossiblewin(possibleWin);
    setplacebetpossiblewin(
      multipliedodds ? multipliedodds * stakeAmount : odds * stakeAmount
    );
    const data = {
      phone: logindetails.username,
      amount: stakeAmount > 1 ? stakeAmount : 0,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };

    //api call for the check balance
    // if ((stakeAmount == minStake)||(stakeAmount > minStake)) {
    axios
      .post(`${baseurl.baseurl}check-balance/`, data, { headers })
      .then(function (response) {
        gettax(response.data.Tax);
        setisloading(false);
        setfinalpayout(response.data.FinalPayout);
        checkbalance(response.data.Balance);
      })
      .catch(function (error) {
        // checkbalance(error.data.Balance);
      });
    // }
  }, [stakeAmount]);

  //highlighted events are not combined functionality
  let duplicateMarketName = [];
  const uniqueMarketName = new Set();
  matchData &&
    matchData.forEach((item) => {
      return !uniqueMarketName.has(item.marketName)
        ? uniqueMarketName.add(item.marketName)
        : duplicateMarketName.push(item.marketName);
    });

  let duplicateGameId = [];
  const uniqueGameId = new Set();
  matchData &&
    matchData.forEach((item) => {
      return !uniqueGameId.has(item.gameid)
        ? uniqueGameId.add(item.gameid)
        : duplicateGameId.push(item.gameid);
    });

  const textfieldstyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: 1,
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        border: 1,
        borderColor: "transparent",
      },
      input: {
        "&::placeholder": {
          textOverflow: "ellipsis !important",
          color: "#000000F2",
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Box
        onClick={() => {
          dispatch(Actions.setShowBetSlip(false));
          dispatch(Actions.showBetSlip(false));
        }}
        sx={{
          display: {
            xs: "flex",
            sm: "none",
            md: "none",
            lg: "none",
            padding: "4px 0px ",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            borderRadius: "10px",
            background: "#323232",
            marginRight: "10px",
            fontSize: "0.8em",
          }}
        >
          <KeyboardArrowLeftSharpIcon
            style={{
              cursor: "pointer",
              fontSize: "1.5em",
              color: "white",
              stroke: "black",
              strokeWidth: 0.5,
            }}
          />
        </div>
        back
      </Box>
      <div className="betSlipTitle">Betslip</div>
      <div
        className="BetslipBody"
        style={{ height: mobileView ? "83%" : "86%" }}
      >
        {!emptyBetslip ? (
          matchData &&
          matchData.map((data, index) => {
            return (
              <div
                key={data.eventId}
                className="betslip-container"
                style={
                  deletedEventId && deletedEventId.includes(data.eventId)
                    ? { color: "red" }
                    : {}
                }
              >
                <div className="Betslip">
                  <div className="BetslipMatchbtw">
                    <div
                      className="Betslipteamsnames"
                      style={
                        deletedEventId && deletedEventId.includes(data.eventId)
                          ? { color: "#0000002b" }
                          : { color: "#747474" }
                      }
                    >
                      {data.team1}&nbsp;-&nbsp;{data.team2}
                    </div>
                    {duplicateGameId.includes(data.gameid) && (
                      <div
                        style={{
                          textAlign: "right",
                          flex: "5%",
                        }}
                      >
                        <ReportProblemIcon
                          size="small"
                          style={{ color: "red", fontSize: "15px" }}
                        />
                      </div>
                    )}
                    <div style={{ cursor: "pointer", width: 16 }}>
                      <CloseIcon
                        style={{
                          fontSize: "16px",
                          color: "#626262",
                          fontWeight: 400,
                        }}
                        onClick={() => handleCloseBetslip(index, data.eventId)}
                      />
                    </div>
                  </div>
                  <div
                    className="BetslipMatchDatetime"
                    style={
                      deletedEventId && deletedEventId.includes(data.eventId)
                        ? { color: "#0000002b" }
                        : { color: "rgba(0, 0, 0, 0.4)" }
                    }
                  >
                    <div>
                      {data.matchdate},&nbsp;{data.matchtime}
                    </div>
                  </div>
                  <div
                    className="BetslipMarketName"
                    style={
                      deletedEventId && deletedEventId.includes(data.eventId)
                        ? { color: "#0000002b" }
                        : { color: "rgba(0, 0, 0, 0.65)" }
                    }
                  >
                    <div style={{ marginTop: 4 }}>{data.marketName}</div>
                  </div>
                  <div className="BetslipMatchbtw">
                    <div
                      className="BetslipEventName"
                      style={
                        deletedEventId && deletedEventId.includes(data.eventId)
                          ? { color: "#0000002b" }
                          : { color: "rgba(0, 0, 0, 0.95)" }
                      }
                    >
                      {data.eventName}
                    </div>
                    <div className="BetslipEventPrice">{getnewvalue(data)}</div>
                  </div>
                  {deletedEventId && deletedEventId.includes(data.eventId) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "1vh 0vh",
                      }}
                    >
                      <div>
                        <RemoveCircleOutlineOutlinedIcon
                          size="small"
                          style={{
                            color: "red",
                            padding: "1vh 1vh 1vh 0vh",
                            display: "flex",
                            fontSize: "1rem",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: "#515151",
                          fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Event has been deleted
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className="EmptyBetslip">Your betslip is empty</div>
          </>
        )}

        {matchData.length > 0 ? (
          <div style={{ padding: "8px 6px" }}>
            {matchData.length > 1 && (
              <div className="Betslip-insertOdd">
                <div style={{ color: "rgba(0,0,0,0.95)" }}>Odds</div>
                <div
                  style={{
                    color: "#3A0088",
                  }}
                >
                  {multipliedodds ? multipliedodds : odds}
                </div>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TextField
                sx={textfieldstyle}
                type="number"
                size="small"
                placeholder="Enter stake"
                margin="normal"
                padding="0px 10px"
                value={stakeAmount}
                onChange={onInputChange}
                fullWidth
                inputProps={{
                  maxLength: 12,
                  style: {
                    fontSize: "14px",
                    fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    color: "rgb(0,0,0,0.8)",
                    background: "rgba(0,0,0,0.1)",
                    borderRadius: "4px",
                    lineHeight: "36px",
                  },
                  width: 315,
                }}
              />
            </div>
            <div className="BetslipMatchbtw">
              <div className="BetslipPossiblewin">
                Possible win for challenge :
              </div>
              <div className="BetslipWinFrw">
                {challengepossiblewin > 0 ? challengepossiblewin : 0} FRw
              </div>
            </div>
            {stakeAmount > 15000 && !(stakeAmount > balance) && (
              <>
                <div className="BetslipMatchbtw">
                  <div className="BetslipPossiblewin">Tax :</div>
                  <div className="BetslipWinFrw">
                    {!isloading ? tax + "FRw" : <CircularProgress size={15} />}
                  </div>
                </div>
                <div className="BetslipMatchbtw">
                  <div className="BetslipPossiblewin">Final payout :</div>
                  <div className="BetslipWinFrw">
                    {!isloading ? (
                      finalpayout + "FRw"
                    ) : (
                      <CircularProgress size={15} />
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="BetslipMatchbtw">
              <div className="BetslipPossiblewin">
                Possible win for place bet :
              </div>
              <div className="BetslipWinFrw">
                {placebetpossiblewin > 0 ? placebetpossiblewin.toFixed(2) : 0}{" "}
                FRw
              </div>
            </div>
            {balance < stakeAmount && (
              <div
                style={{
                  display: "flex",
                  background: "#e5e5e5",
                  margin: "10px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    padding: "4px 0px 2px 7px",
                  }}
                >
                  <ReportProblemIcon
                    size="small"
                    style={{ color: "red", fontSize: "15px" }}
                  />
                </div>
                <div
                  className="BetslipPossiblewin"
                  style={{
                    padding: "5px",
                  }}
                >
                  Insufficient balance
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="betSlipEmpty">Your betslip is empty</div>
        )}
        {betslipdataselector &&
          betslipdataselector
            .map((game) => game.gameid)
            .filter((currentValue, currentIndex) => {
              return (
                betslipdataselector &&
                betslipdataselector
                  .map((game) => game.gameid)
                  .indexOf(currentValue) !== currentIndex
              );
            }).length > 0 && (
            <div className="betslipSameMarket-error">
              <div
                style={{
                  padding: "4px 0px 2px 7px",
                }}
              >
                <ReportProblemIcon
                  size="small"
                  style={{ color: "red", fontSize: "15px" }}
                />
              </div>
              <span className="info-text">
                Highlighted events cannot be combined
              </span>
            </div>
          )}
        {deletedEvent && deletedEvent.length > 0 && (
          <div className="betslipSameMarket-error">
            <div
              style={{
                padding: "4px 0px 2px 7px",
              }}
            >
              <ReportProblemIcon
                size="small"
                style={{ color: "orange", fontSize: "15px" }}
              />
            </div>
            <span className="info-text">There are deleted events</span>
          </div>
        )}
        {stakeAmount && stakeAmount < 1000 && (
          <div className="betslipSameMarket-error">
            <div
              style={{
                padding: "4px 0px 2px 7px",
              }}
            >
              <ReportProblemIcon
                size="small"
                style={{ color: "red", fontSize: "15px" }}
              />
            </div>
            <span className="info-text">
              Minimum total stake requirements not met(1000FRw)
            </span>
          </div>
        )}
        <Box className="stakeBox">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <TextField
                variant="standard"
                id="firstTextField"
                name="firstTextfield"
                type="number"
                size="small"
                inputRef={inputRef}
                value={firstStakeValue}
                onChange={handleFirstStakeValue}
                onClick={handleFirstTextField}
                onMouseDown={() => {
                  handleMouseDown("firstTextField");
                }}
                onMouseUp={() => handleMouseUp("firstTextField")}
                sx={{
                  input: {
                    cursor: "pointer",
                    padding: "0px",
                    textAlign: "center",
                    fontSize: "14px",
                    height: "32px",
                    color:
                      isMouseDown === "firstTextField"
                        ? "white"
                        : "rgba(0,0,0,0.7)",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                    // border:!edit?"1px solid rgba(0,0,0,0.7)":"none",
                    background: "rgba(0,0,0,0.1)",
                  },
                  style: { borderWidth: 0 },
                }}
                InputProps={{
                  readOnly: edit ? true : false,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <TextField
                id="secondTextField"
                name="secondTextfield"
                type="number"
                variant="standard"
                size="small"
                value={secondStakeValue}
                onClick={handleSecondTextField}
                onChange={handleSecondStakeValue}
                onMouseDown={() => {
                  handleMouseDown("secondTextField");
                }}
                onMouseUp={() => handleMouseUp("secondTextField")}
                sx={{
                  input: {
                    cursor: "pointer",
                    padding: "0px",
                    textAlign: "center",
                    fontSize: "14px",
                    height: "32px",
                    // border:!edit?"1px solid rgba(0,0,0,0.7)":"none",
                    color:
                      isMouseDown === "secondTextField"
                        ? "white"
                        : "rgba(0,0,0,0.7)",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                    background: "rgba(0,0,0,0.1)",
                  },
                }}
                InputProps={{
                  readOnly: edit ? true : false,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <TextField
                id="thirdTextField"
                name="thirdTextfield"
                variant="standard"
                type="number"
                size="small"
                value={thirdStakeValue}
                onChange={handleThirdStakeValue}
                onClick={handleThirdTextField}
                onMouseDown={() => {
                  handleMouseDown("thirdTextField");
                }}
                onMouseUp={() => handleMouseUp("thirdTextField")}
                sx={{
                  input: {
                    cursor: "pointer",
                    padding: "0px",
                    textAlign: "center",
                    fontSize: "14px",
                    height: "32px",
                    // border:!edit?"1px solid rgba(0,0,0,0.7)":"none",
                    color:
                      isMouseDown === "thirdTextField"
                        ? "white"
                        : "rgba(0,0,0,0.7)",
                    borderRadius: "4px",
                    boxSizing: "border-box",
                    background: "rgba(0,0,0,0.1)",
                  },
                }}
                InputProps={{
                  readOnly: edit ? true : false,
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <IconButton
                onClick={handleEdit}
                sx={{
                  borderRadius: "4px",
                  padding: "5px",
                  background: "rgba(0,0,0,0.1)",
                  "&:hover": { backgroundColor: "rgba(0,0,0,.1)" },
                }}
              >
                {edit ? (
                  firstStakeValue === localStorage.getItem("firststake") ||
                  1000 ? (
                    <Tooltip title={"Edit stakes"}>
                      <EditIcon />
                    </Tooltip>
                  ) : (
                    <DoneIcon />
                  )
                ) : (
                  <DoneIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
          <Typography component="div" className="minStake">
            {stakeError === true ? (
              <div
                style={{
                  display: "flex",
                  background: "#e5e5e5",
                  margin: "10px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                  }}
                >
                  <ReportProblemIcon
                    size="small"
                    style={{ color: "red", fontSize: "15px" }}
                  />
                </div>
                <div
                  className="BetslipPossiblewin"
                  style={{
                    padding: "5px",
                  }}
                >
                  Min favorite stake amount is 1000FRw
                </div>
              </div>
            ) : (
              ""
            )}
          </Typography>
        </Box>

        {matchData.length > 1 && (
          <div className="betslipSameMarket-error">
            <div
              style={{
                padding: "4px 0px 2px 7px",
              }}
            >
              <ReportProblemIcon
                size="small"
                style={{ color: "red", fontSize: "15px" }}
              />
            </div>
            <span className="info-text">
              {"Please select any one event to 'CHALLENGE'"}
            </span>
          </div>
        )}
        <>
          <div className="betslipbtn">
            {deletedEventId == "" &&
            !(stakeAmount > balance) &&
            matchData.length > 0 &&
            matchData.length < 2 &&
            stakeAmount >= minStake ? (
              <Tooltip title="Challenge">
                <Button
                  className="betslip-button"
                  variant="contained"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "12px",
                      lg: "12px",
                    },
                  }}
                  style={{
                    background: "#1C226B",
                    color: "#ffffff",
                    width: oddschanged == true ? "90%" : " 70%",
                  }}
                  onClick={handleClickOpen}
                >
                  {oddschanged == false
                    ? "Challenge"
                    : "Accept changes and challenge"}
                </Button>
              </Tooltip>
            ) : (
              <Button
                className="betslip-button"
                variant="contained"
                disabled={true}
                sx={{
                  fontSize: { xs: "12px", sm: "12px", md: "12px", lg: "12px" },
                }}
                style={{
                  background: "rgba(0,0,0,.1)",
                  color: "rgba(0,0,0,0.3)",
                  width: oddschanged == true ? "90%" : " 70%",
                }}
              >
                {oddschanged == false
                  ? "Challenge"
                  : "Accept changes and challenge"}
              </Button>
            )}
            <Dialog
              open={openChallenge}
              fullScreen={mobileView ? true : false}
              fullWidth={mobileView ? true : false}
              maxWidth="sm"
              className="dialog"
              PaperProps={{
                sx: {
                  width: { xs: "95%", sm: "95%", md: "70%", lg: "70%" },
                  // height:{ xs: "82%", sm: "84%", md: "84%", lg: "78%" },
                  //height: mobileView ? "100%" : "78%",
                  maxHeight: mobileView ? "85%" : "100%",
                  boxShadow: "none",
                  margin: 0,
                },
              }}
            >
              <ChallengeModalTabs
                updatedodds={updatedodds && updatedodds}
                onclose={handleClose}
                challengepossiblewin={challengepossiblewin}
                data={betsliplist}
                stakeamount={stakeAmount}
                setstakeamount={setStakeAmount}
              />
            </Dialog>
          </div>
          <div className="betslipbtn">
            {deletedEventId == "" &&
            !(stakeAmount > balance) &&
            matchData.length > 0 &&
            stakeAmount >= minStake &&
            uniqueGameId.size == matchData.length ? (
              <Tooltip title="Place bet">
                <Button
                  className="betslip-button"
                  variant="contained"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "12px",
                      lg: "12px",
                    },
                  }}
                  style={{
                    background: "#1C226B",
                    color: "#ffffff",
                    width: oddschanged == true ? "90%" : " 70%",
                  }}
                  onClick={handleplacebet}
                >
                  {oddschanged == false
                    ? "Place bet"
                    : "Accept changes and place bet"}
                </Button>
              </Tooltip>
            ) : (
              <Button
                className="betslip-button"
                disabled={true}
                variant="contained"
                sx={{
                  fontSize: { xs: "12px", sm: "12px", md: "12px", lg: "12px" },
                }}
                style={{
                  background: "rgba(0,0,0,.1)",
                  color: "rgba(0,0,0,0.3)",
                  width: oddschanged == true ? "90%" : " 70%",
                }}
              >
                {oddschanged == false
                  ? "Place bet"
                  : "Accept changes and place bet"}
              </Button>
            )}
          </div>
        </>
      </div>
    </React.Fragment>
  );
};

export default BetSlip;
