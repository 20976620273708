import React, { useState } from "react";
import { Paper, TextField, InputAdornment } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import * as Actions from "../../../../Redux/Actions";
import axios from "axios";
import baseurl from "../../../../Config";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "../../../../Styles/ChallengemodeStyles/ChallengeMode.css";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

const textfieldstyle = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: 1.5,
      borderColor: "#ec1087",
    },
    "&:hover fieldset": {
      border: 1.5,
      borderColor: "#ec1087",
    },
  },
};

const UsersData = (props) => {
  const value = props.value;
  const dispatch = useDispatch();
  const [userData, setuserData] = useState();
  const [searchvalue, setsearchvalue] = useState("");
  const [page, setPage] = React.useState(1);
  const [loader, setloader] = useState(false);
  const [responsecount, setresponsecount] = React.useState();
  const count = Math.ceil(responsecount / 10);

  const handleAddUser = (id, name, number) => {
    dispatch(Actions.addUserData({ id: id, name: name }));
    dispatch(
      Actions.selectedUserData({ id: id, name: name, usernumber: number })
    );
    dispatch(Actions.isPrivateChallenge(true));
    props.adduser(0);
  };

  const handlepageChange = (event, value) => {
    setPage(value);
  };

  const accesskey = localStorage.getItem("accesskey");
  React.useEffect(() => {
    if (value === 1) {
      axios
        .get(
          `${baseurl.baseurl}follower_list/?page=${page}&search=${searchvalue}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(accesskey)}`,
            },
          }
        )
        .then((response) => {
          setresponsecount(response.data.count);
          setuserData(response.data.results);
          setloader(false);
        })
        .catch((err) => {
          toast.error(err, { duration: 1000 });
        });
    } else {
      axios
        .get(
          `${baseurl.baseurl}following_list/?page=${page}&search=${searchvalue}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(accesskey)}`,
            },
          }
        )
        .then((response) => {
          setresponsecount(response.data.count);
          setuserData(response.data.results);
          setloader(false);
        })
        .catch((err) => {
          toast.error(err, { duration: 1000 });
        });
    }
  }, [value, page, searchvalue]);

  return (
    <React.Fragment>
      <>
        {userData ? (
          <div>
            <div className="textfield-container">
              <TextField
                id="outlined-basic"
                size="small"
                placeholder="Search by user name or number"
                style={{ width: "95%" }}
                value={searchvalue}
                autoComplete="off"
                onChange={(e) => {
                  setsearchvalue(e.target.value);
                  setloader(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {loader ? (
                        <CircularProgress size={20} sx={{ color: "#ec1087" }} />
                      ) : (
                        <SearchIcon className="search-icon" />
                      )}
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: "14px",
                    textAlign: "center",
                    color: "rgb(0,0,0,0.8)",
                    background: "#fff",
                  },
                }}
                sx={textfieldstyle}
                inputProps={{ maxLength: 32 }}
              />
            </div>
            {userData.length > 0 ? (
              <div>
                <div>
                  <TableContainer
                    component={Paper}
                    style={{
                      maxHeight: "60vh",
                      overflowY: "overlay",
                      height: "50vh",
                      background: "#f2f2f2",
                    }}
                  >
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow
                          style={{
                            borderBottom: "1px solid #000000",
                            background: "#f2f2f2",
                          }}
                        >
                          <TableCell className="table-heading" align="center">
                            User
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            User Name
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            User number
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userData.map((users, index) => (
                          <TableRow
                            key={index}
                            style={{
                              borderBottom: "1px solid #0000001f",
                              background: "#f2f2f2",
                            }}
                          >
                            <TableCell className="table-data" align="center">
                              <Avatar
                                alt={users.username}
                                src="/static/images/avatar/1.jpg"
                                sx={{ height: 28, width: 28, margin: "auto" }}
                              />
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {users.p2p_user_id}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              {users.p2p_user_id}
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <Button
                                className="btn"
                                size="small"
                                variant="contained"
                                onClick={(e) =>
                                  handleAddUser(
                                    users.id,
                                    users.username,
                                    users.p2p_user_id
                                  )
                                }
                              >
                                select
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="pagination">
                    <Pagination
                      count={count}
                      page={page}
                      onChange={handlepageChange}
                      size="small"
                      sx={{
                        "& button": {
                          fontSize: "12px",
                          fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px",
                  fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                  fontSize: "16px",
                }}
              >
                <div>No results found</div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
          </div>
        )}
      </>
    </React.Fragment>
  );
};

export default UsersData;
