import React, { useState, useEffect } from "react";
import { Button, Divider, InputAdornment, TextField } from "@mui/material";
import "../../../../Styles/ChallengemodeStyles/ChallengeMode.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useDispatch } from "react-redux";
import * as Actions from "../../../../Redux/Actions";
import axios from "axios";
import baseurl from "../../../../Config";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { toast } from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";

const textfieldstyle = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: 1.5,
      borderColor: "#ec1087",
    },
    "&:hover fieldset": {
      border: 1.5,
      borderColor: "#ec1087",
    },
  },
};

const ListOfGroups = (props) => {
  const [groupname, setgroupname] = useState();
  const [groupsList, setGroupsList] = useState();
  const [page, setPage] = React.useState(1);
  const [responsecount, setresponsecount] = React.useState();
  const [searchGroup, setSearchGroup] = useState("");
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const count = Math.ceil(responsecount / 10);
  const getgroupname = (groupname) => {
    setgroupname(groupname);
  };

  const accesskey = localStorage.getItem("accesskey");
  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}p2p-group/?page=${page}&search=${searchGroup}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setresponsecount(response.data.count);
        setGroupsList(response.data.results);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [searchGroup, page, responsecount]);

  const handleAddGroup = (id, name, grouplength) => {
    dispatch(Actions.addGroupsData({ id: id, name: name }));
    dispatch(
      Actions.selectedUserData({ id: id, name: name, length: grouplength })
    );
    dispatch(Actions.isPrivateChallenge(true));
    props.adduser(0);
  };

  const handlepageChange = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      <>
        <div className="group-container">
          <div className="groupsSearch">
            <TextField
              id="outlined-basic"
              size="small"
              placeholder="Search by group name"
              style={{ width: "95%" }}
              value={searchGroup}
              sx={textfieldstyle}
              onChange={(e) => {
                setSearchGroup(e.target.value);
                setLoader(true);
              }}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {loader ? (
                      <CircularProgress size={20} sx={{ color: "#ec1087" }} />
                    ) : (
                      <SearchIcon className="search-icon" />
                    )}
                  </InputAdornment>
                ),
                style: {
                  fontSize: "14px",
                  textAlign: "center",
                  color: "rgb(0,0,0,0.8)",
                  background: "#fff",
                },
              }}
            />
          </div>
        </div>
        <Divider />
        {groupsList ? (
          <div>
            {groupsList.length > 0 ? (
              <div>
                <TableContainer
                  style={{
                    maxHeight: "60vh",
                    overflowY: "overlay",
                    height: "50vh",
                    background: "#f2f2f2",
                  }}
                >
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow
                        style={{
                          borderBottom: "1px solid #000000",
                          background: "#f2f2f2",
                        }}
                      >
                        <TableCell className="table-heading" align="center">
                          Group Name
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Group Users
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupsList &&
                        groupsList?.map((group, id) => {
                          return (
                            <TableRow
                              key={id}
                              style={{ background: "#f2f2f2" }}
                            >
                              <TableCell className="table-data" align="center">
                                {group.group_name}
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <PeopleAltIcon />
                                  <PeopleAltIcon /> +
                                  {group.group_members.length}
                                  &nbsp;View Users
                                </div>
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                <Button
                                  className="btn"
                                  size="small"
                                  variant="contained"
                                  onClick={(e) => {
                                    group.group_members.length > 0
                                      ? handleAddGroup(
                                          group.group_id,
                                          group.group_name,
                                          group.group_members.length
                                        )
                                      : toast.error(
                                          "cannot place a challenge with empty group",
                                          { duration: 1000 }
                                        );
                                  }}
                                >
                                  Select
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="pagination">
                  <Pagination
                    count={count}
                    page={page}
                    onChange={handlepageChange}
                    size="small"
                    sx={{
                      "& button": {
                        fontSize: "12px",
                        fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                      },
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px",
                  fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                  fontSize: "16px",
                }}
              >
                <div>No results found</div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="rectangular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
          </div>
        )}
      </>
    </React.Fragment>
  );
};

export default ListOfGroups;
