import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../Redux/Actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-hot-toast";

var ws = "";
const requestid = Math.random().toString(36).substring(2, 7);
const SocketConnection = (callback) => {
  ws = new WebSocket("wss://eu-swarm-gorillagames.betconstruct.com");

  //session start
  var startsession = {
    command: "request_session",
    params: {
      site_id: 1334,
      language: "en",
    },
    rid: requestid,
  };

  ws.onopen = () => {
    if (ws.readyState === 1) {
      localStorage.setItem("Readystate", ws.readyState);
      ws.send(JSON.stringify(startsession));
    }
  };

  ws.onmessage = (res) => {
    callback(res.returnValue);
  };

  ws.onclose = (e) => {
    setTimeout(() => {
      SocketConnection();
    }, 5000);
  };
  return ws;
};
export { SocketConnection };

export class WebSocketConnection {
  sendcommand(command) {
    // ws.send(JSON.stringify(command))
    return new Promise((resolve, reject) => {
      // Wait for WebSocket connection to be established
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(command));
      } else {
        ws.addEventListener(
          "open",
          () => {
            ws.send(JSON.stringify(command));
          },
          { once: true }
        );
      }

      // Listen for response
      ws.addEventListener(
        "message",
        (event) => {
          const response = JSON.parse(event.data);
          resolve(response);
        },
        { once: true }
      );

      // Handle errors
      ws.addEventListener(
        "error",
        (event) => {
          toast.error("something went wrong, please try again");
          reject(event);
        },
        { once: true }
      );
    });
  }
}

export function SocketCommands() {
  var subscribe;
  const dispatch = useDispatch();
  ws.onmessage = (res) => {
    subscribe = "";
    const data = JSON.parse(res.data);
    if (data.rid == 0) {
      subscribe = data.rid;
      dispatch(Actions.subscribedata(data));
    }
  };
  const subscribedata = useSelector((state) => state.subscribedata);
  const betslipdataselector = useSelector((state) => state.betslipmatchdata);
  var CryptoJS = require("crypto-js");
  // const restoreloginrid = Math.random().toString(36).substring(2, 7);
  
  // var restore_login = {
  //   command: "restore_login",
  //   params: {
  //     user_id: 1050279088,
  //     auth_token: "DE1775FE346D883F88B74CFBC0063D90",
  //   },
  //   rid: restoreloginrid,
  // };

  // React.useEffect(() => {
  //   (async () => {
  //     const websocket = new WebSocketConnection();
  //     var sessionrestore = await websocket.sendcommand(restore_login);
  //     console.log("session console",sessionrestore.data.data)
  //   })();
  // }, []);

  React.useEffect(() => {
    var eventprices = [];
    var eventids = [];
    var eventidprice =
      betslipdataselector &&
      betslipdataselector.map((data) => {
        !eventids.includes(data.eventId) && eventids.push(data.eventId);
        !eventprices.includes(data.eventprice) &&
          eventprices.push(data.eventPrice);
        return {
          eventid: data.eventId,
          eventprice: data.evenPrice,
        };
      });
      
    const eventrid = Math.random().toString(36).substring(2, 7);
    var getoddprice = {
      command: "get",
      params: {
        source: "betting",
        what: {
          event: ["id", "price", "name"],
        },
        where: {
          game: {
            type: {
              "@in": [0, 2],
            },
          },
          event: {
            id: {
              "@in": eventids,
            },
          },
        },
        subscribe: true,
      },
      rid: eventrid,
    };

    //socket call back
    const websocket = new WebSocketConnection();
    (async () => {
      // var sportsresponse = await websocket.sendcommand(allSports)
      var eventresponse = await websocket.sendcommand(getoddprice);
      const dataobj = {
        // sportsdata: sportsresponse,
        eventdata: eventresponse,
      };
      // dataobj && callback(dataobj)
      dispatch(Actions.wssporteventdata(dataobj));
    })();
  }, [betslipdataselector, subscribedata]);
}
