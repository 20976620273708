import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import { Grid, Box } from "@mui/material";
import SideMenuSports from "../components/CreateChallengeComp/SideSportsMenuConatiner/SideMenuSports";
import Matchdetails from "../components/CreateChallengeComp/MatchDataContainer/Matchdatacomp";
import BetSlip from "../components/CreateChallengeComp/BetSlipcontainer/BetSlipData";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { SocketCommands } from "../Utilis/Websocket";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../Assets/logo.png";
import SliderCarousel from "../components/CreateChallengeComp/CarouselComponent";
import axios from "axios";
import baseurl from "../Config";

const CreateChallenge = (props) => {
  const wssporteventdata = useSelector((state) => state.wssporteventdata);
  const wssportdata = useSelector((state) => state.wssportdata);
  const displayMatchDetails = useSelector((state) => state.displayMatchDetails);
  const showBetSlip = useSelector((state) => state.showBetSlip);
  const dataselector = useSelector((state) => state.sidemenudata);
  const betslipdataselector = useSelector((state) => state.betslipmatchdata);
  const isLoading = useSelector((state) => state.isLoading);
  const theme = useTheme();
  const tabletView = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const laptopView = useMediaQuery(theme.breakpoints.between("md", "xl"));

  const accesskey = localStorage.getItem("accesskey");
  const [content, setContent] = useState();

  useEffect(() => {
    axios
      .get(`${baseurl.baseurl}images/active_list/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        // const sortedImages = response.data.sort((a, b) => a.order_key - b.order_key);
        // const urls = sortedImages.map((image) => image.url);

        const data = response.data.map((val) => val.url);
        setContent(data);
      })
      .catch((err) => {});
  }, []);

  return (
    <React.Fragment>
      {mobileView ? (
        <Grid
          container
          spacing={1}
          style={{ padding: "3px 5px 0 5px", height: "100%" }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItemsl: "center",
              padding: "0 10px 0px 15px",
            }}
          >
            {content && content.length > 0 && (
              <SliderCarousel content={content} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={3}
            lg={3}
            sx={{ height: "98%", padding: "8px 4px 0px 8px" }}
          >
            {betslipdataselector && showBetSlip ? (
              <BetSlip initialStakeValue={props.initialStakeValue} />
            ) : displayMatchDetails ? (
              dataselector.competitionid ? (
                <Matchdetails />
              ) : (
                <Stack spacing={2} style={{ padding: 10 }}>
                  <Skeleton variant="rounded" width="90%" height={35} />
                  <Skeleton variant="rounded" width="90%" height={35} />
                  <Skeleton variant="rounded" width="90%" height={35} />
                  <Skeleton variant="rounded" width="90%" height={35} />
                </Stack>
              )
            ) : (
              <SideMenuSports />
            )}
          </Grid>
        </Grid>
      ) : tabletView ? (
        <Grid container sx={{ height: "100%" }}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItemsl: "center",
              padding: "0 10px 0px 15px",
            }}
          >
            {content && content.length > 0 && (
              <SliderCarousel content={content} />
            )}
          </Grid>
          <Grid container style={{ padding: "3px 5px 0 5px", height: "100%" }}>
            <Grid
              item
              xs={12}
              sm={7}
              md={3}
              lg={3}
              sx={{ height: "98%", padding: "8px 4px 0px 8px" }}
            >
              {displayMatchDetails ? (
                dataselector.competitionid ? (
                  <Matchdetails />
                ) : (
                  <Stack spacing={2} style={{ padding: 10 }}>
                    <Skeleton variant="rounded" width="90%" height={35} />
                    <Skeleton variant="rounded" width="90%" height={35} />
                    <Skeleton variant="rounded" width="90%" height={35} />
                    <Skeleton variant="rounded" width="90%" height={35} />
                  </Stack>
                )
              ) : (
                <SideMenuSports />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              lg={3}
              sx={{ height: "98%", padding: "8px 4px 0px 8px" }}
            >
              {!wssporteventdata || wssporteventdata === null ? (
                <Stack spacing={2} style={{ padding: 10 }}>
                  <Skeleton variant="rounded" width="90%" height={35} />
                  <Skeleton variant="rounded" width="90%" height={35} />
                  <Skeleton variant="rounded" width="90%" height={35} />
                  <Skeleton variant="rounded" width="90%" height={35} />
                </Stack>
              ) : (
                <BetSlip initialStakeValue={props.initialStakeValue} />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={{ height: "auto" }}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItemsl: "center",
              padding: "0 10px 0px 15px",
            }}
          >
            {content && content.length > 0 && (
              <SliderCarousel content={content} />
            )}
          </Grid>
          <Grid
            container
            sx={{ padding: "3px 5px 3px 5px", maxHeight: "100vh" }}
          >
            <Grid
              item
              xs={12}
              sm={7}
              md={3}
              lg={3}
              sx={{ height: "98%", padding: "8px 4px 0px 8px" }}
            >
              <SideMenuSports />
            </Grid>
            <Grid
              item
              xs={12}
              sm={0}
              md={5}
              lg={6}
              sx={{ height: "98%", padding: "8px 4px 0px 8px" }}
            >
              {dataselector.competitionid ? (
                <Matchdetails />
              ) : dataselector.length < 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  No active sports or matches found
                </div>
              ) : (
                <Stack spacing={2} style={{ padding: 10 }}>
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                  <Skeleton variant="rounded" width={600} height={35} />
                </Stack>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              lg={3}
              sx={{ height: "98%", padding: "8px 4px 0px 8px" }}
            >
              {!wssporteventdata || wssporteventdata === null ? (
                <Stack spacing={2} style={{ padding: 10 }}>
                  <Skeleton variant="rounded" width={250} height={35} />
                  <Skeleton variant="rounded" width={250} height={35} />
                  <Skeleton variant="rounded" width={250} height={35} />
                  <Skeleton variant="rounded" width={250} height={35} />
                </Stack>
              ) : (
                <BetSlip initialStakeValue={props.initialStakeValue} />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "#d9d9d9a3",
        }}
        open={isLoading && isLoading}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <img
            src={logo}
            alt="logoimage"
            className="logoimage"
            style={{ width: "20vh", height: "20vh" }}
          ></img>
          <CircularProgress
            size={"4rem"}
            style={{
              color: "#ec1087",
              margin: "auto",
            }}
          />
          <span
            style={{
              color: "#ec1087",
              fontSize: "10px",
              display: "flex",
              justifyContent: "center",
              margin: "5px",
            }}
          >
            please wait, loading...
          </span>
        </Box>
      </Backdrop>
    </React.Fragment>
  );
};

export default CreateChallenge;
