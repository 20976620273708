import React, { useState, useEffect } from "react";
import { Pagination, Paper } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import baseurl from "../../../Config";
import gold from "../../../Assets/gold.png";
import silver from "../../../Assets/silver.png";
import bronze from "../../../Assets/bronze.png";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { toast } from "react-hot-toast";
import "../../../Styles/LeaderboardStyles/Leaderboard.css";

const Leaderslist = (props) => {
  const accesskey = localStorage.getItem("accesskey");
  const [value, setValue] = React.useState(0);
  const [Leaderslist, setLeaderslist] = useState();
  const [responsecount, setResponseCount] = useState("");
  const [count, setcount] = useState();
  const [page, setPage] = useState(1);
  const [topThree, setTopThree] = useState();
  const [leaderBoardList, setLeaderBoardList] = useState();

  const otherHeights = 254;
  let tableHeight = window.innerHeight - otherHeights;

  const handleChange = (event, value) => {
    setPage(value);
  };

  const maskNumber = (number) => {
    const numString = number.toString();
    const firstThree = numString.substring(0, 3);
    const lastFour = numString.substring(numString.length - 4);
    const maskedNumber = `${firstThree}***${lastFour}`;
    return maskedNumber;
  };

  useEffect(() => {
    const valuefunction = () => {
      if (props.value === 0) {
        return 30;
      } else if (props.value === 1) {
        return 7;
      } else if (props.value === 2) {
        return 1;
      }
    };

    axios
      .get(
        `${
          baseurl.baseurl
        }leader-board/stats/?interval=${valuefunction()}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(accesskey)}`,
          },
        }
      )
      .then((response) => {
        if (page === 1) {
          setLeaderBoardList(response.data.results);
        }
        setResponseCount(Math.ceil(response.data.count / 10));
        setLeaderslist(response.data.results);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [page]);

  return Leaderslist ? (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {Leaderslist.length !== 0 ? (
        <div className="Leaderslist">
          <div className="leaderboard-top3">
            <div className="leaderboardtop">
              <div className="leaderboardname">
                {leaderBoardList && maskNumber(leaderBoardList[1].p2p_user_id)}
              </div>
              <div>
                <img src={silver} width={60} height={60} alt="img" />
              </div>
            </div>
            <div className="leaderboardtop">
              <div className="leaderboardname">
                {leaderBoardList && maskNumber(leaderBoardList[0].p2p_user_id)}
              </div>
              <div>
                <img src={gold} width={80} height={80} alt="img" />
              </div>
            </div>
            {leaderBoardList[2] && (
              <div className="leaderboardtop">
                <div className="leaderboardname">
                  {leaderBoardList &&
                    maskNumber(leaderBoardList[2].p2p_user_id)}
                </div>
                <div>
                  <img src={bronze} width={60} height={60} alt="img" />
                </div>
              </div>
            )}
          </div>
          <TableContainer
            component={Paper}
            style={{
              maxHeight: tableHeight + "px",
              overflowY: "overlay",
            }}
          >
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow
                  style={{
                    borderBottom: "1px solid #000000",
                    background: "#f2f2f2",
                  }}
                >
                  <TableCell className="table-heading" align="center">
                    Rank
                  </TableCell>
                  <TableCell className="table-heading" align="center">
                    User Name
                  </TableCell>
                  <TableCell className="table-heading" align="center">
                    Points
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Leaderslist.length > 0 &&
                  Leaderslist.map((item, index) => (
                    <TableRow
                      key={index}
                      style={{
                        background: "#f2f2f2",
                        borderBottom: "1px solid #0000001f",
                        height: "45px",
                      }}
                    >
                      <TableCell
                        className="table-data"
                        align="center"
                        style={{ fontWeight: 600 }}
                      >
                        {/* {item.date} */}
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell className="table-data" align="center">
                        {maskNumber(item.p2p_user_id)}
                      </TableCell>
                      <TableCell className="table-data" align="center">
                        {item.points}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="pagination">
            <Pagination
              count={responsecount}
              page={page}
              onChange={handleChange}
              size="small"
              sx={{
                "& button": {
                  fontSize: "12px",
                  fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                },
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            fontSize: "16px",
            fontFamily: "Roboto,Arial,Helvetica,sans-serif",
            width: "100%",
          }}
        >
          <div>No results found</div>
        </div>
      )}
    </div>
  ) : (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={2} style={{ padding: 10 }}>
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
          <Skeleton variant="rounded" width={600} height={35} />
        </Stack>
      </div>
    </div>
  );
};

export default Leaderslist;
