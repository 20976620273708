import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  TableHead,
  Divider,
} from "@mui/material";
import toast from "react-hot-toast";
import Pagination from "@mui/material/Pagination";
import baseurl from "../../Config";
import "../../Styles/FriendsModule/FriendsModule.css";
import axios from "axios";
import { padding } from "@mui/system";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";

const NewGroups = (props) => {
  const accesskey = localStorage.getItem("accesskey");
  const getgroupname = props.getgroupname;
  const addNewMember = props.addNewMember;
  const [searcheduser, setsearchUsers] = useState("");
  const [responsecount, setresponsecount] = useState("");
  const [friends, getfriends] = useState();
  const [groupName, setGroupName] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [userId, setUserId] = useState();
  const [page, setPage] = React.useState(1);
  const [selectedUser, setSelectedUser] = useState(false);
  const [enableAddButton, setEnableAddButton] = useState(false);
  const otherHeights = 185;
  let tableHeight = window.innerHeight - otherHeights;

  const handlepageChange = (event, value) => {
    setPage(value);
  };

  const count = Math.ceil(responsecount / 10);

  //get list of friends
  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}p2p-user/?page=${page}&search=${searcheduser}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setresponsecount(response.data.count);
        getfriends(response.data.results);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [page, searcheduser, responsecount]);

  // add new group
  // const HandleAddNewGroup = () => {
  //   const data = {
  //     group_name: groupName,
  //     group_members: groupMembers,
  //   };

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${JSON.parse(accesskey)}`,
  //   };

  //   axios
  //     .post(`${baseurl.baseurl}p2p-group/`, { ...data }, { headers })
  //     .then(function (response) {
  //       toast.success(response.data.message);
  //       const addfriendtogroup = friends.filter(
  //         (user) => !groupMembers.includes(user.id)
  //       );
  //       if (page > 1 && addfriendtogroup.length === 0) {
  //         setPage(page - 1);
  //       }
  //       getfriends(addfriendtogroup);
  //       setresponsecount(addfriendtogroup.length);
  //       setSelectedUser(false);
  //       props.onclose();
  //     })
  //     .catch(function (error) {
  //       toast.error(error.response.data.detail);
  //     });
  //   getgroupname(groupName);
  //   addNewMember(groupMembers);
  // };

  const HandleAddNewGroup = () => {
    const data = {
      group_name: groupName,
      group_members: groupMembers,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };

    // eslint-disable-next-line no-lone-blocks
    {
      groupMembers.length > 0
        ? axios
            .post(`${baseurl.baseurl}p2p-group/`, { ...data }, { headers })
            .then(function (response) {
              toast.success(response.data.message, { duration: 1000 });
              const addfriendtogroup = friends.filter(
                (user) => !groupMembers.includes(user.id)
              );
              if (page > 1 && addfriendtogroup.length === 0) {
                setPage(page - 1);
              }
              getfriends(addfriendtogroup);
              setresponsecount(addfriendtogroup.length);
              setSelectedUser(false);
              props.onclose();
            })
            .catch(function (error) {
              toast.error(error.response.data.detail, { duration: 1000 });
            })
        : toast.error("Group can't be empty", { duration: 1000 });
    }
    getgroupname(groupName);
    addNewMember(groupMembers);
  };

  const Handleemptygroup = () => {
    toast.error(`Please add the group name`, {
      id: "Group name mandatory",
      duration: 1000,
    });
  };

  const handleback = () => {
    props.onclose();
  };

  const handleChange = (e) => {
    if (/^\s/.test(e.target.value)) {
      setEnableAddButton(true);
    } else {
      setEnableAddButton(false);
    }
    setGroupName(e.target.value);
  };

  const handleAddUser = (id, action) => {
    if (action === "add") {
      setGroupMembers((prevVal) => {
        return [...prevVal, id];
      });
      setSelectedUser(true);
    } else {
      const cancelAddIds = groupMembers.filter((remId) => remId !== id);
      setGroupMembers(cancelAddIds);
    }
  };

  useEffect(() => {
    userId && setGroupMembers((prev) => [...prev, userId]);
  }, [userId]);

  return (
    <React.Fragment>
      <div claasName="newGroup-container">
        <div style={{ background: "#f2f2f2" }}>
          <div onClick={handleback} className="back-btn">
            <div
              style={{
                display: "flex",
                borderRadius: "10px",
                background: "#323232",
                marginRight: "10px",
                fontSize: "1em",
              }}
            >
              <KeyboardArrowLeftSharpIcon
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  color: "white",
                  stroke: "white",
                  strokeWidth: 0.5,
                }}
              />
            </div>
            back
          </div>
        </div>
        <div className="group-container">
          <div className="headingText">New Group</div>
          <div className="groupsSearch">
            <TextField
              id="outlined-basic"
              size="small"
              placeholder="Type Group Name"
              onChange={handleChange}
              value={groupName}
              error={/^\s/.test(groupName)}
              sx={{
                "& .MuiOutlinedInput-input": {
                  background: "#cccccc",
                  padding: "5px 14px",
                },
              }}
              inputProps={{ maxLength: 32 }}
              helperText={
                /^\s/.test(groupName)
                  ? "First Letter of Group Name can't be space"
                  : ""
              }
            />
          </div>
          <div className="addbtn-container">
            <Button
              disabled={!selectedUser}
              type="submit"
              style={{
                fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                fontSize: "12px",
                background: selectedUser === true ? "#1c226b" : "#0000000D",
                color: "#ffffff",
                textTransform: "uppercase",
              }}
              onClick={
                groupName === "" || /^\s/.test(groupName)
                  ? Handleemptygroup
                  : HandleAddNewGroup
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <TableContainer
          style={{
            maxHeight: tableHeight + "px",
            overflowY: "overlay",
          }}
        >
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow
                style={{
                  borderBottom: "1px solid #000000",
                  background: "#f2f2f2",
                }}
              >
                <TableCell className="table-heading" align="center">
                  User Name
                </TableCell>
                <TableCell className="table-heading" align="center">
                  User Number
                </TableCell>
                <TableCell className="table-heading" align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {friends &&
                friends.map((user, id) => (
                  <TableRow
                    key={id}
                    style={{
                      borderBottom: "1px solid #0000001f",
                      background: "#f2f2f2",
                    }}
                  >
                    <TableCell className="table-data" align="center">
                      {user.p2p_user_id}
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      {user.p2p_user_id}
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      {!groupMembers.includes(user.id) ? (
                        <Button
                          className="addGroupMember-btn"
                          size="small"
                          variant="contained"
                          onClick={(e) => handleAddUser(user.id, "add")}
                          style={{
                            backgroundColor: !enableAddButton
                              ? "#1c226b"
                              : "#e6e6e6",
                            color: !enableAddButton ? "white" : "red",
                          }}
                          disabled={enableAddButton}
                        >
                          add
                        </Button>
                      ) : (
                        <Button
                          className="btn"
                          size="small"
                          variant="contained"
                          onClick={(e) => handleAddUser(user.id, "cancel")}
                          style={{ background: "#1c226b" }}
                        >
                          cancel
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            maxWidth: "100%",
            background: "#f2f2f2",
            display: "flex",
            justifyContent: "right",
            padding: "10px 0",
          }}
        >
          <div align="center">
            <Pagination
              count={count}
              page={page}
              onChange={handlepageChange}
              size="small"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewGroups;
