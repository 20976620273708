import * as React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import ChallengeMode from "./ChallengeMode";
import CloseIcon from "@mui/icons-material/Close";
import UsersDataComp from "./UserDatacontainer/UsersDataComp";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../Redux/Actions";
import "../../../Styles/ChallengemodeStyles/ChallengeMode.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function TabPanel(props) {
  const theme = useTheme();
  const { children, value, index, ...other } = props;
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "90%", maxHeight: "100%" }}
    >
      {value === index && (
        <Box sx={{ height: "100%", width: "inherit" }}>
          <div style={{ height: "inherit", padding: "4px 1px 0px" }}>
            {children}
          </div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ChallengModelTabs(props) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const closeModal = props.onclose;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const close = () => {};

  return (
    <React.Fragment>
      <Box
        sx={{
          background: "#ffffff",
          width: { xs: "100%", sm: "100%", md: "600px", lg: "600px" },
          height: "100%",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
          className="challengeModel-tabs-box"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ sx: { bgcolor: "#EC1087" } }}
            sx={{ minHeight: "40px", display: "flex", alignItems: "center" }}
          >
            <Tab
              label="Challenge Mode"
              {...a11yProps(0)}
              className="challengeModelTab"
              variant="fullwidth"
              style={{
                color: !value ? "#ffffff" : "rgb(0,0,0)",
                background: !value ? "#EC1087" : "",
                textTransform: "capitalize",
              }}
              sx={{ minHeight: "40px" }}
            />
            <Tab
              label="Users"
              {...a11yProps(1)}
              className="challengeModelTab"
              style={{
                color: !value ? "rgb(0,0,0)" : "#ffffff",
                background: !value ? "" : "#EC1087",
                textTransform: "capitalize",
              }}
              sx={{ minHeight: "40px" }}
              onClick={(e) => {
                dispatch(Actions.showChallenger(false));
              }}
            />
          </Tabs>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              flex: 1,
              paddingRight: "16px",
              justifyContent: "end",
            }}
          >
            <CloseIcon
              onClick={() => {
                props.onclose();
                dispatch(Actions.selectedUserData(""));
                dispatch(Actions.setExptime(false));
                dispatch(Actions.addUserData(""));
              }}
              style={{ color: "rgb(0,0,0)", height: "20px", width: "20px" }}
            />
          </div>
        </Box>
        <TabPanel value={value} index={0}>
          <ChallengeMode
            close={closeModal}
            setstakeamount={props.setstakeamount}
            adduser={setValue}
            challengepossiblewin={props.challengepossiblewin}
            stakeamount={props.stakeamount}
            updatedodds={props.updatedodds}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UsersDataComp adduser={setValue} />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
}
