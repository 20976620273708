import React, { useState, useEffect } from "react";
import { Button, Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import "../../../Styles/CreateChallengeStyles/Sidemenustyles/SideMenuSports.css";
import AccordionDetails from "@mui/material/AccordionDetails";
import * as Actions from "../../../Redux/Actions";
import {
  Padding,
  SettingsInputAntennaTwoTone,
  SportsBaseball,
} from "@mui/icons-material";
import { SocketCommands } from "../../../Utilis/Websocket.js";
import axios from "axios";
import baseurl from "../../../Config";

function SideMenuSports(props) {
  //state for sports
  // const [sportsData, setSportsData] = useState(null);
  const accesskey = localStorage.getItem("accesskey");
  const [sportid, setsportid] = useState();
  const [sportname, setsportname] = useState();
  const [expandedsports, setExpandedsports] = useState(0);
  const [expandedregion, setExpandedregion] = useState(0);
  const [regionid, setregionid] = useState();
  const [competitionid, setcompetitionid] = useState();
  const [competitionname, setcompetitionname] = useState();
  const siteid = useSelector((state) => state.siteid);
  const wssportdata = useSelector((state) => state.wssportdata);
  var CryptoJS = require("crypto-js");
  var gamedetails = localStorage.getItem("gamedata");
  var gamebytes =
    gamedetails && CryptoJS.AES.decrypt(gamedetails, "dt-crg-ggp2p");
  var decryptedBSData =
    gamedetails && JSON.parse(gamebytes.toString(CryptoJS.enc.Utf8));
  const subscribedata = useSelector((state) => state.subscribedata);
  // const [sportsData, updatesportsData] = useState()

  // const [intialeventcompetition, setintialeventcompetition] = useState(true)
  const sortbyorder = (a, b) => {
    return a.order - b.order;
  };
  const dispatch = useDispatch();
  const sportsData = wssportdata && Object.values(wssportdata);

  const handlesportchange = (isExpanded, panel, id, name) => {
    setExpandedsports(isExpanded ? panel : false);
    setsportid(id);
    setsportname(name);

    const data = {
      sport_id: id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };

    axios
      .post(`${baseurl.baseurl}games-detail/`, { ...data }, { headers })
      .then(function (response) {
        const array = response && response.data.data;
        dispatch(Actions.restrictedmarkets(array));
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  };

  const handleregionchange = (regionExpanded, panel, id) => {
    setExpandedregion(regionExpanded ? panel : false);
    setregionid(id);
  };

  const handlecompetition = (
    sportsid,
    sportsname,
    regionid,
    competitionid,
    competitionname
  ) => {
    dispatch(Actions.initialcompetition(false));
    setcompetitionid(competitionid);
    setcompetitionname(competitionname);
    const userseldata = {
      sportid: sportsid,
      sportname: sportsname,
      regionid: regionid,
      competitionid: competitionid,
      leaguename: competitionname,
    };

    //dispatch data to the redux store
    userseldata.sportid &&
      userseldata.regionid &&
      userseldata.competitionid &&
      dispatch(Actions.senduserseldata(userseldata));
    dispatch(Actions.showMatchData(true));
    dispatch(Actions.displayMatchDetails(true));
  };

  React.useEffect(() => {
    //   function flatten(obj) {
    //     var flattenedObject = {};
    //     traverseAndFlatten(obj, flattenedObject);
    //     return flattenedObject;
    // }
    // subscribedata && subscribedata.data && console.log("subscribedData____:", flatten(subscribedata?.data[Object.keys(subscribedata?.data)]));
  }, [subscribedata]);

  return (
    <div style={{ height: "100%" }}>
      <div className="sportsTitle">Sports</div>
      <div className="sports-list-container">
        {sportsData && sportsData.length > 0 ? (
          sportsData?.sort(sortbyorder).map((sport, id) => {
            return (
              <Accordion
                className="sports-accordion"
                key={id}
                onChange={(event, isExpanded) =>
                  handlesportchange(isExpanded, id, sport?.id, sport?.name)
                }
                style={{ margin: "3px" }}
                expanded={
                  expandedsports === id || sport[id]?.name === "football"
                }
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  style={{ background: "#0000000D", borderRadius: "4px" }}
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        background: expandedsports === id ? "#ec1087" : "",
                        borderRadius: "4px",
                        color: expandedsports === id ? "#ffffff" : "",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      font: "12px Roboto,Arial,Helvetica,sans-serif",
                      padding: "0px 10px 0px 0px",
                      fontWeight: "500",
                      color: "rgba(0,0,0)",
                    }}
                  >
                    {sport?.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className="sports-accordion-details"
                  sx={{ padding: 0 }}
                >
                  {sport &&
                    sport.region &&
                    Object.values(sport.region)
                      .sort(sortbyorder)
                      .map((region, i) => {
                        return (
                          <Accordion
                            className="sports-accordion"
                            key={i}
                            onChange={(event, regionExpanded) =>
                              handleregionchange(regionExpanded, i, region.id)
                            }
                            expanded={expandedregion === i}
                            // defaultExpanded={expandedregion === sport.region[0].region.id}
                            TransitionProps={{ unmountOnExit: true }}
                            style={{ margin: "3px", overflow: "hidden" }}
                          >
                            <AccordionSummary
                              style={{
                                background: "#0000001A",
                                borderRadius: "4px",
                              }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              key={id}
                            >
                              <Typography
                                style={{
                                  font: "12px Roboto,Arial,Helvetica,sans-serif",
                                  padding: "0px 10px 0px 0px",
                                  color: "rgba(0,0,0,0.8)",
                                }}
                              >
                                {region.name}
                              </Typography>
                            </AccordionSummary>
                            <Box
                              sx={{ background: "#0000001A", padding: "0 2px" }}
                            >
                              <AccordionDetails
                                sx={{
                                  height: "100%",
                                  border: "5px solid #0000001A",
                                  borderRadius: "10px",
                                  background: "white",
                                  padding: "3px 6px",
                                }}
                              >
                                {region &&
                                  region.competition &&
                                  Object.values(region.competition).length >
                                    0 &&
                                  Object.values(region.competition)
                                    .sort(sortbyorder)
                                    .map((competition, i) => {
                                      return (
                                        <AccordionSummary
                                          key={i}
                                          style={{
                                            background: "#00000026",
                                            margin: "2px 0",
                                            borderRadius: "5px",
                                            width: "100%",
                                          }}
                                          onClick={(event) => {
                                            handlecompetition(
                                              sport?.id,
                                              sport?.name,
                                              region.id,
                                              competition.id,
                                              competition.name
                                            );
                                          }}
                                        >
                                          <Typography
                                            key={i}
                                            style={{
                                              font: "12px Roboto,Arial,Helvetica,sans-serif",
                                              color: "rgba(0,0,0,0.8)",
                                            }}
                                          >
                                            {competition.name}
                                          </Typography>
                                        </AccordionSummary>
                                      );
                                    })}
                              </AccordionDetails>
                            </Box>
                          </Accordion>
                        );
                      })}
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : sportsData && sportsData.length === 0 ? (
          <div
            style={{
              font: "15px Roboto,Arial,Helvetica,sans-serif",
              margin: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No active sports
          </div>
        ) : (
          <>
            <Stack spacing={2} style={{ padding: 10 }}>
              <Skeleton variant="rounded" width={280} height={35} />
              <Skeleton variant="rounded" width={280} height={35} />
              <Skeleton variant="rounded" width={280} height={35} />
              <Skeleton variant="rounded" width={280} height={35} />
            </Stack>
            <Stack spacing={2} style={{ padding: 10 }}>
              <Skeleton variant="rounded" width={280} height={35} />
              <Skeleton variant="rounded" width={280} height={35} />
              <Skeleton variant="rounded" width={280} height={35} />
              <Skeleton variant="rounded" width={280} height={35} />
            </Stack>
          </>
        )}
      </div>
    </div>
  );
}

export default SideMenuSports;
