import React, { useState } from "react";
import { Paper, TextField, InputAdornment, Divider } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import "../../Styles/FriendsModule/FriendsModule.css";
import baseurl from "../../Config";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { toast } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce, initial } from "lodash";

const UsersData = (props) => {
  var accesskey = localStorage.getItem("accesskey");
  const value = props.value;
  const [searcheduser, setsearchUsers] = useState("");
  const [responsecount, setresponsecount] = React.useState("");
  const [userslist, setuserslist] = useState();
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const count = Math.ceil(responsecount / 10);
  const [removeuserdetails, setuserdetails] = useState({});
  const [loader, setloader] = useState(false);
  const otherHeights = 185;
  let tableHeight = window.innerHeight - otherHeights;

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleClickOpen = (p2puser_id, userid) => {
    setuserdetails({
      userid: p2puser_id,
      dbid: userid,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!value) {
      axios
        .get(
          `${baseurl.baseurl}follower_list/?page=${page}&search=${searcheduser}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(accesskey)}`,
            },
          }
        )
        .then((response) => {
          setloader(false);
          setresponsecount(response.data.count);
          setuserslist(response.data.results);
        })
        .catch((err) => {
          toast.error(err, { duration: 1000 });
        });
    } else {
      axios
        .get(
          `${baseurl.baseurl}following_list/?page=${page}&search=${searcheduser}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${JSON.parse(accesskey)}`,
            },
          }
        )
        .then((response) => {
          setloader(false);
          setresponsecount(response.data.count);
          setuserslist(response.data.results);
        })
        .catch((err) => {
          toast.error(err, { duration: 1000 });
        });
    }
  }, [value, page, searcheduser, responsecount]);

  const handleactionbtns = (userid, dbid) => {
    const remove = {
      remove: dbid,
    };
    const unfollow = {
      unfollow: dbid,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };
    axios
      .post(
        `${baseurl.baseurl}unfollow/`,
        value === 0 ? { ...remove } : { ...unfollow },
        { headers }
      )
      // .then(function (response) {})
      .then(() => {
        const updateuserlist = userslist.filter(
          (event) => event.p2p_user_id !== userid
        );
        setuserslist(updateuserlist);
        setresponsecount(updateuserlist.length);
        value === 0
          ? toast.success("Removed Successfully", { duration: 1000 })
          : toast.success("Unfollowed Successfully", { duration: 1000 });
      })
      .catch(function (error) {});
    setOpen(false);
  };

  if (page > 1 && userslist.length === 0) {
    setPage(page - 1);
  }

  const textfieldstyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: 1.5,
        borderColor: "#ec1087",
        background: "rgba(0,0,0,0.1)",
      },
      "&:hover fieldset": {
        border: 1.5,
        borderColor: "#ec1087",
      },
    },
  };

  const searchuser = (user) => {
    debounce(usersdata(user));
  };

  const usersdata = (e) => {
    setsearchUsers(e);
  };

  return (
    <React.Fragment>
      <>
        <div className="add-friend-container">
          <div className="search-container">
            <div className="textfield-container">
              <TextField
                sx={textfieldstyle}
                id="outlined-basic"
                size="small"
                // sx={{ width: { xs: "90%", sm: "70%", md: "50%", lg: "50%" } }}
                placeholder="Search by username or number"
                autoComplete="off"
                value={searcheduser}
                onChange={(e) => {
                  searchuser(e.target.value);
                  // setsearchUsers(e.target.value);
                  setloader(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {loader ? (
                        <CircularProgress size={20} sx={{ color: "#ec1087" }} />
                      ) : (
                        <SearchIcon className="search-icon" />
                      )}
                    </InputAdornment>
                  ),
                  maxLength: 16,
                  style: {
                    fontSize: "14px",
                    textAlign: "center",
                    color: "rgb(0,0,0,0.8)",
                    background: "white",
                  },
                }}
                inputProps={{ maxLength: 32 }}
              />
            </div>
          </div>
        </div>
        <Divider />
        {userslist && userslist ? (
          <div>
            {userslist && userslist.length > 0 ? (
              <div>
                <TableContainer
                  component={Paper}
                  style={{
                    width: "100%",
                    maxHeight: tableHeight + "px",
                    overflowY: "overlay",
                  }}
                >
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow
                        style={{
                          borderBottom: "1px solid #000000",
                          background: "#f2f2f2",
                        }}
                      >
                        <TableCell className="table-heading" align="center">
                          User
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          User Name
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          User Number
                        </TableCell>
                        <TableCell className="table-heading" align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userslist.map((user, index) => (
                        <TableRow key={index} style={{ background: "#f2f2f2" }}>
                          <TableCell className="table-data" align="center">
                            <Avatar
                              alt={user.username}
                              src="/static/images/avatar/1.jpg"
                              sx={{ height: 32, width: 32, margin: "auto" }}
                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {user.p2p_user_id}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {user.p2p_user_id}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Button
                              className="btn"
                              variant="contained"
                              onClick={() => {
                                handleClickOpen(user.p2p_user_id, user.id);
                              }}
                            >
                              {!value ? "Remove" : "Unfollow"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="pagination">
                  <Pagination
                    count={count}
                    page={page}
                    onChange={handleChange}
                    size="small"
                    sx={{
                      "& button": {
                        fontSize: "12px",
                        fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                      },
                    }}
                  />
                </div>
                <Dialog
                  PaperProps={{
                    style: {
                      backgroundColor: "white",
                      boxShadow: "none",
                    },
                  }}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    }}
                  >
                    "Do you really want to remove or unfollow this friend ?"
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      className="btn"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) =>
                        handleactionbtns(
                          removeuserdetails.userid,
                          removeuserdetails.dbid
                        )
                      }
                      autoFocus
                      className="btn"
                      variant="contained"
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  // background: "#f2f2f2",
                  fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                  fontSize: "16px",
                }}
              >
                <div>No results found</div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
            <Stack
              spacing={2}
              sx={{ display: "flex", flexDirection: "column", m: 2 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
                <Skeleton variant="rectangular" width={210} height={30} />
              </div>
            </Stack>
          </div>
        )}
      </>
    </React.Fragment>
  );
};

export default UsersData;
