import React from "react";
import MyChallengesTabs from "../components/MyChallengesComp/MyChallengesTabs";
import "../Styles/MyChallengesStyles/MyChallenges.css";
import * as Actions from "../Redux/Actions";
import { useDispatch } from "react-redux";

const MyChallenges = () => {
  const dispatch = useDispatch();
  dispatch(Actions.showMatchData(true));
  // dispatch(Actions.senduserseldata(""))
  return (
    <div
      className="myChallenge-container"
      // style={{
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   padding: "10px",
      //   width: "100%",
      //   height:"100%"
      // }}
    >
      <MyChallengesTabs />
    </div>
  );
};

export default MyChallenges;
