import React from "react";
import { Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import baseurl from "../../../Config";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "./../../../Styles/MyChallengesStyles/MyChallenges.css";
import { toast } from "react-hot-toast";

const ExpiredChallenges = () => {
  const [page, setPage] = React.useState(1);
  const [expiredChallengeslist, setExpiredChallengeslist] = React.useState();
  const [responsecount, setresponsecount] = React.useState("");
  const otherHeights = 125;
  let tableHeight = window.innerHeight - otherHeights;

  const count = Math.ceil(responsecount / 10);
  const handleChange = (event, value) => {
    setPage(value);
  };

  //Active challenges list
  const accesskey = localStorage.getItem("accesskey");
  React.useEffect(() => {
    axios
      .get(`${baseurl.baseurl}expired-challenges/?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setresponsecount(response.data.count);
        setExpiredChallengeslist(response.data.results);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [page]);

  //Accept challenge

  return (
    <>
      {expiredChallengeslist ? (
        <div>
          {expiredChallengeslist.length > 0 ? (
            <>
              <TableContainer
                style={{
                  maxHeight: tableHeight + "px",
                  overflowY: "overlay",
                }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow className="tableheaders">
                      <TableCell className="table-heading" align="center">
                        Date
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Time
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Sport
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Match
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Type
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Status
                      </TableCell>
                      <TableCell className="table-heading" align="center">
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expiredChallengeslist &&
                      expiredChallengeslist.map((challenge, index) => (
                        <TableRow key={index} className="tableRowdetails">
                          <TableCell className="table-data" align="center">
                            {challenge.bet_slip_challenge[0].game_date}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {challenge.bet_slip_challenge[0].game_time}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {challenge.bet_slip_challenge[0].sport_name}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {challenge.bet_slip_challenge[0].game_name}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {challenge.type_of_challenge}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {challenge.is_declined === true
                              ? "Rejected"
                              : "Expired"}
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            {challenge.user_stack_amount} FRw
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="mychallengepagination">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  size="small"
                  sx={{
                    "& button": {
                      fontSize: "12px",
                      fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                background: "#f2f2f2",
                fontSize: "16px",
                fontFamily: "Roboto,Arial,Helvetica,sans-serif",
              }}
            >
              <div> No results found</div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
          <Stack
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", m: 2 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
              <Skeleton variant="rectangular" width={210} height={30} />
            </div>
          </Stack>
        </div>
      )}
    </>
  );
};

export default ExpiredChallenges;
