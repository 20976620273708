import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../../Styles/CreateChallengeStyles/Matchdatacontainerstyles/Matchdatacomp.css";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import MarketsTypeTabs from "./MarketsTypes/MarketTypeTabs";
import axios from "axios";
import baseurl from "../../../Config";
import { useDispatch } from "react-redux";
import * as Actions from "../../../Redux/Actions";
import {
  SocketCommands,
  WebSocketConnection,
} from "../../../Utilis/Websocket.js";
import { Box, Divider } from "@mui/material";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import LanguageIcon from "@mui/icons-material/Language";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Matchdetails = () => {
  //----redux data selector
  const theme = useTheme();
  const dataselector = useSelector((state) => state.sidemenudata);
  const matchDataSelector = useSelector((state) => state.showMatchData);
  const ismatchesloading = useSelector((state) => state.ismatchesloading);
  const betslipdataselector = useSelector((state) => state.betslipmatchdata);
  const setShowBetSlip = useSelector((state) => state.setShowBetSlip);
  const gamedetails = useSelector((state) => state.sidemenudata);
  const rid = Math.random().toString(36).substring(2, 7);
  //const [openMarkets, setOpenMarkets] = useState(false);
  const [matchdata, updatematchdata] = useState();
  // const [gamedata, updategamedata] = useState({});
  const [groupnames, updatemarketgroupnames] = useState();
  const dispatch = useDispatch();
  const Access_Token = localStorage.getItem("accesskey");
  const [betslipdata, updatebetslipdata] = useState(betslipdataselector);
  var CryptoJS = require("crypto-js");
  const restrictedmarkets = useSelector((state) => state.restrictedmarkets);
  const showBetSlip = useSelector((state) => state.showBetSlip);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const mviewbackbtncmdcall = useSelector((state) => state.mviewbackbtncmdcall);

  useEffect(() => {
    updatebetslipdata(betslipdataselector);
  }, [betslipdataselector]);

  //sort the market types
  function compareorder(a, b) {
    return a.order - b.order;
  }
  //-----------------
  const websocket = new WebSocketConnection();
  const teamsHandler = async (gameid) => {
    const getgroupnames = {
      command: "get",
      params: {
        source: "betting",
        what: {
          market: ["group_name", "group_id", "order"],
        },
        where: {
          game: {
            id: {
              "@in": [gameid],
            },
          },
          market: {
            type: {
              "@in": restrictedmarkets,
            },
          },
        },
        subscribe: true,
      },
      rid: rid,
    };

    var response = await websocket.sendcommand(getgroupnames);
    const marketsdata =
      response &&
      response.data &&
      response.data.data &&
      (response.data.data.market !== ({} || "" || []) ||
        response.data.data.market.length > 0) &&
      response.data.data.market;
    var Arofmarkets = Object.values(marketsdata && marketsdata);
    const removeduplicates =
      Arofmarkets !== [] &&
      Arofmarkets.filter(
        (v, i, a) =>
          a.findIndex((element) =>
            ["group_id", "group_name"].every((k) => element[k] === v[k])
          ) === i
      );
    dispatch(
      Actions.uniqueGroupNames(
        removeduplicates && removeduplicates.length > 1
          ? removeduplicates.sort(compareorder)
          : removeduplicates
      )
    );
    dispatch(Actions.showMatchData(false));
    dispatch(Actions.showBetSlip(false));
  };

  const backToMatches = () => {
    //setOpenMarkets(false);
    dispatch(Actions.showMatchData(true));
  };

  useEffect(() => {
    dispatch(Actions.ismatchesloading(true));
    const data = {
      sport_id: dataselector.sportid,
      region_id: dataselector.regionid,
      competition_id: dataselector.competitionid,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(Access_Token)}`,
    };
    dataselector &&
      axios
        .post(
          `${baseurl.baseurl}games-detail/main_market/`,
          { ...data },
          { headers }
        )
        .then(function (response) {
          const output = response.data.data;
          updatematchdata(output);
          dispatch(Actions.ismatchesloading(false));
          dispatch(Actions.initialcompetition(false));
        })
        .catch(function (error) {});
  }, [dataselector]);

  const emptydata = () => {
    if (matchdata.length === 0 || matchdata === ([] || "")) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            fontFamily: "Roboto,Arial,Helvetica,sans-serif",
            margin: "10px",
          }}
        >
          No matches found for this competition
        </div>
      );
    } else {
      return "";
    }
  };

  // const connect = SocketCommands(null);
  const updatePageData = (pageData) => {
    const { eventId, eventName, evenPrice, marketName, marketid } = pageData;
    eventId &&
      eventName &&
      evenPrice &&
      marketName &&
      marketid &&
      updatebetslipdata((prev) => {
        var output = [...prev, pageData];
        if (prev && prev.map((m) => m.eventId).includes(pageData.eventId)) {
          output = prev.filter((el) => el.eventId !== pageData.eventId);
        }
        return output;
      });
    var initialprice = [];
    betslipdata &&
      betslipdata.map((data) => {
        !initialprice.includes(data.evenPrice) &&
          initialprice.push(data.evenPrice);
      });
    localStorage.setItem(
      "gamedetails",
      CryptoJS.AES.encrypt(
        JSON.stringify(gamedetails),
        "dt-crg-ggp2p"
      ).toString()
    );
    // setIsActive(eventId);
    dispatch(Actions.setShowBetSlip(true));
    // betslipdata && dispatch(Actions.showBetSlip(true));
  };

  //dispatch the user selected data to the redux store
  // for the desktop and the tablet view
  React.useEffect(() => {
    if (!mobileView) {
      localStorage.setItem(
        "betslipdata",
        CryptoJS.AES.encrypt(
          JSON.stringify(betslipdata),
          "dt-crg-ggp2p"
        ).toString()
      );
      betslipdata && dispatch(Actions.sendbetslipdata(betslipdata));
    }
  }, [betslipdata]);

  //socket calls for the mobile view temp test
  React.useEffect(() => {
    if (mobileView && matchDataSelector) {
      var eventprices = [];
      var eventids = [];
      var eventidprice =
        betslipdata &&
        betslipdata.map((data) => {
          !eventids.includes(data.eventId) && eventids.push(data.eventId);
          !eventprices.includes(data.eventprice) &&
            eventprices.push(data.eventPrice);
          return {
            eventid: data.eventId,
            eventprice: data.evenPrice,
          };
        });
      const eventrid = Math.random().toString(36).substring(2, 7);
      var getoddprice = {
        command: "get",
        params: {
          source: "betting",
          what: {
            event: ["id", "price", "name"],
          },
          where: {
            game: {
              type: {
                "@in": [0, 2],
              },
            },
            event: {
              id: {
                "@in": eventids,
              },
            },
          },
          subscribe: true,
        },
        rid: eventrid,
      };

      const websocket = new WebSocketConnection();
      (async () => {
        setShowBetSlip && betslipdata && dispatch(Actions.showBetSlip(true));
        var eventresponse = await websocket.sendcommand(getoddprice);
        const dataobj = {
          eventdata: eventresponse,
        };
        dispatch(Actions.wssporteventdata(dataobj));
        localStorage.setItem(
          "betslipdata",
          CryptoJS.AES.encrypt(
            JSON.stringify(betslipdata),
            "dt-crg-ggp2p"
          ).toString()
        );
        betslipdata && dispatch(Actions.sendbetslipdata(betslipdata));
      })();
    }
  }, [betslipdata]);

  //--------------------------------------------------
  return (
    <div className="matchdataconatainer">
      {matchDataSelector ? (
        <>
          <Box
            className="item-eventview"
            onClick={() => {
              dispatch(Actions.displayMatchDetails(false));
              if (mobileView) {
                dispatch(Actions.showMatchData(true));
              }
            }}
            sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}
          >
            <div
              style={{
                display: "flex",
                borderRadius: "10px",
                background: "#323232",
                marginRight: "10px",
                fontSize: "0.8em",
              }}
            >
              <KeyboardArrowLeftSharpIcon
                style={{
                  cursor: "pointer",
                  fontSize: "1.5em",
                  color: "white",
                  stroke: "white",
                  strokeWidth: 0.5,
                }}
              />
            </div>
            Sports
          </Box>
          {!ismatchesloading && dataselector.competitionid && (
            <div className="item-eventview">
              <div
                style={{
                  display: "flex",
                  width: "4%",
                  height: "42%",
                  alignItems: "center",
                  justifyContent: "center",
                  orderRadius: "2px",
                  margin: "5px",
                  background: "#323232",
                  borderRadius: "2px",
                }}
              >
                <LanguageIcon
                  variant="filled"
                  style={{ fontSize: "1em", color: "white" }}
                />
              </div>
              {dataselector.leaguename}
            </div>
          )}
          {!ismatchesloading && matchdata ? (
            <>
              {matchdata && matchdata.length > 0
                ? matchdata.map((game, index) => {
                    return (
                      <div key={index}>
                        <Accordion
                          className="accordion-root"
                          defaultExpanded={true}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                              backgroundColor: "#0000000D",
                              // marginTop: "0.3rem",
                              margin: "3px 0 2px",
                              font: "0.8rem secondaryFont, sans-serif",
                              borderRadius: "4px",
                              marginBottom: "2px",
                            }}
                          >
                            <div>{Object.keys(game)[0]}</div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              padding: 0,
                            }}
                          >
                            {Object.values(game).map((data) => {
                              return Object.values(data).map((game, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="competition-details-section"
                                    style={{
                                      borderRadius: "5px",
                                      overflow: "hidden",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    <div
                                      className="competition-details-header"
                                      onClick={(message, event) =>
                                        teamsHandler(
                                          game.id,
                                          dispatch(
                                            Actions.gameData({
                                              gameid: game.id,
                                              team1: game.team1_name,
                                              team2: game.team2_name,
                                              time: game.time,
                                              date: game.start_ts,
                                            })
                                          )
                                          // updategamedata({
                                          //   gameid: game.id,
                                          //   team1: game.team1_name,
                                          //   team2: game.team2_name,
                                          //   time: game.time,
                                          //   date: game.start_ts,
                                          // })
                                        )
                                      }
                                    >
                                      <div className="teamnames">
                                        {game.team1_name} &nbsp;{" "}
                                        <span
                                          style={{
                                            color: "#6c6c6c",
                                            fontSize: "11px",
                                          }}
                                        >
                                          vs
                                        </span>{" "}
                                        &nbsp;
                                        {game.team2_name}
                                      </div>
                                    </div>
                                    <div
                                      className="competition-details-header"
                                      style={{ padding: 0 }}
                                    >
                                      {Object.keys(game.market)
                                        .map((key) => game.market[key])
                                        .map((market) => {
                                          return Object.keys(market.event)
                                            .map((key) => market.event[key])
                                            .sort(compareorder)
                                            .map((event, i) => {
                                              const events = Object.keys(
                                                market.event
                                              );
                                              return (
                                                <div
                                                  className="market-group-holder-bc"
                                                  style={{
                                                    flex:
                                                      events.length === 3
                                                        ? "0 0 33.33%"
                                                        : "0 0 50%",
                                                  }}
                                                  key={i}
                                                >
                                                  <div
                                                    className={
                                                      betslipdataselector &&
                                                      betslipdataselector.length >
                                                        0 &&
                                                      betslipdataselector
                                                        .map((x) => x.eventId)
                                                        .includes(event.id)
                                                        ? "market-group-item-bc active"
                                                        : "market-group-item-bc"
                                                    }
                                                    onClick={(e) => {
                                                      updatePageData({
                                                        gameid: game.id,
                                                        team1: game.team1_name,
                                                        team2: game.team2_name,
                                                        matchtime: game.time,
                                                        matchdate:
                                                          game.start_ts,
                                                        eventId: event.id,
                                                        eventName: event.name,
                                                        evenPrice: event.price,
                                                        marketName: market.name,
                                                        marketid: market.id,
                                                      });
                                                    }}
                                                  >
                                                    <div className="market-name-bc">
                                                      {event.name}
                                                    </div>
                                                    <div
                                                      className={
                                                        betslipdataselector &&
                                                        betslipdataselector.length >
                                                          0 &&
                                                        betslipdataselector
                                                          .map((x) => x.eventId)
                                                          .includes(event.id)
                                                          ? "market-odd-bc active"
                                                          : "market-odd-bc"
                                                      }
                                                    >
                                                      {event.price}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            });
                                        })}
                                    </div>
                                    <div
                                      className="competition-details-header3"
                                      style={{
                                        height: "24px",
                                        background: "#e5e5e5",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          padding: "8px",
                                          alignItems: "center",
                                        }}
                                      >
                                        {/* <AccessTimeIcon style={{ color: "#00000080", fontSize: '0.8em', paddingRight:"10px" }} /> */}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="currentColor"
                                          className="bi bi-stopwatch"
                                          viewBox="0 0 16 16"
                                          style={{
                                            color: "#373737",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                                          <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                                        </svg>
                                        <div className="matchtime">
                                          {game.time}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding: "8px",
                                        }}
                                      >
                                        <BarChartIcon
                                          style={{
                                            color: "#2e2e2e",
                                            fontSize: "0.8em",
                                            paddingRight: "10px",
                                          }}
                                        />
                                        <Divider
                                          orientation="vertical"
                                          variant="middle"
                                          style={{
                                            color: "#cecece",
                                            margin: 0,
                                            height: "auto",
                                          }}
                                        />
                                        <AddIcon
                                          style={{
                                            fontSize: "0.7em",
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingLeft: "10px",
                                          }}
                                        />
                                        <div className="matchtime">
                                          {game.markets_count}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              });
                            })}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })
                : emptydata()}
            </>
          ) : dataselector.length < 1 ? (
            <div>No active sports or matches found</div>
          ) : (
            <>
              <Stack spacing={2} style={{ padding: 10 }}>
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
                <Skeleton variant="rounded" width={"100%"} height={35} />
              </Stack>
            </>
          )}
        </>
      ) : (
        <>
          {/* {!matchDataSelector && uniquegroupnames.length > 0 ? ( */}
          <MarketsTypeTabs
            // uniquegroupnames={uniquegroupnames}
            // gamedata={gamedata}
            tabnames={groupnames}
            onclose={backToMatches}
          />
          {/* ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                font: "1rem secondaryFont, sans-serif",
              }}
            >
              oops!, something went wrong, try again!
            </div>
          )} */}
        </>
      )}
    </div>
  );
};
export default Matchdetails;
