import React, { useState, useEffect } from "react";
import { Button, Divider, Skeleton, Stack } from "@mui/material";
import "../../Styles/FriendsModule/FriendsModule.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SearchIcon from "@mui/icons-material/Search";
import NewGroups from "./NewGroups";
import { InputAdornment } from "@mui/material";
import { TextField } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import baseurl from "../../Config";
import axios from "axios";
import EditModelTabs from "./Editgroup/Editgrouptabs";
import toast from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

const ListOfGroups = (props) => {
  const accesskey = localStorage.getItem("accesskey");
  //styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    background: "rgb(242, 242, 242)",
  };

  const [newGroup, setNewGroup] = useState(false);
  const [groupname, setgroupname] = useState();
  const [groupsList, setGroupsList] = useState();
  const [editgroup, handleeditgroup] = useState(false);
  const [groupid, setgroupid] = useState("");
  const [editgroupname, seteditgroupname] = useState("");
  const [groupMembers, setGroupMembers] = useState();
  const [searcheduser, updatesearchUsers] = useState("");
  const [responsecount, setResponseCount] = useState("");
  const [page, setPage] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [deletegroupid, setdeletegroupid] = React.useState(null);
  const [loader, setloader] = useState(false);
  const otherHeights = 185;
  let tableHeight = window.innerHeight - otherHeights;

  const count = Math.ceil(responsecount / 10);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const getgroupname = (groupname) => {
    setgroupname(groupname);
  };
  const handleNewMember = () => {
    setGroupMembers(groupMembers);
  };
  const handleClickOpen = (id) => {
    setdeletegroupid(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //fetching groups list
  useEffect(() => {
    axios
      .get(`${baseurl.baseurl}p2p-group/?page=${page}&search=${searcheduser}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(accesskey)}`,
        },
      })
      .then((response) => {
        setResponseCount(response.data.count);
        setGroupsList(response.data.results);
        setloader(false);
      })
      .catch((err) => {
        toast.error(err, { duration: 1000 });
      });
  }, [searcheduser, page, newGroup, responsecount, editgroup]);

  const groupHandler = () => {
    setNewGroup(true);
  };

  const openeditgroup = (id, name) => {
    handleeditgroup(true);
    setgroupid(id);
    seteditgroupname(name);
  };
  const closeeditgroup = () => {
    handleeditgroup(false);
  };
  const backToListofGroups = () => {
    setNewGroup(false);
  };

  const handleDeleteGroup = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(accesskey)}`,
    };
    axios
      .delete(`${baseurl.baseurl}p2p-group/${id}/`, { headers })
      .then((response) => {
        toast.success("Group is deleted successfully", { duration: 1000 });
      })
      .then(() => {
        const deleteGroup = groupsList.filter((group) => group.group_id !== id);
        if (page > 1 && deleteGroup.length === 0) {
          setPage(page - 1);
        }
        setGroupsList(deleteGroup);
        setResponseCount(deleteGroup.length);
      })
      .catch((err) => toast.error(err, { duration: 1000 }));
    setOpen(false);
  };

  const textfieldstyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: 1.5,
        borderColor: "#ec1087",
        background: "rgba(0,0,0,0.1)",
      },
      "&:hover fieldset": {
        border: 1.5,
        borderColor: "#ec1087",
      },
    },
  };

  return (
    <React.Fragment>
      {!newGroup ? (
        <>
          <div className="group-container">
            <div className="headingText">Groups</div>
            <div className="groupsSearch">
              <TextField
                sx={textfieldstyle}
                id="group-textfield"
                size="small"
                placeholder="Search by group name"
                value={searcheduser}
                autoComplete="off"
                onChange={(e) => {
                  updatesearchUsers(e.target.value);
                  setloader(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {loader ? (
                        <CircularProgress size={20} sx={{ color: "#ec1087" }} />
                      ) : (
                        <SearchIcon className="search-icon" />
                      )}
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: "14px",
                    textAlign: "center",
                    color: "rgb(0,0,0,0.8)",
                    background: "white",
                  },
                }}
                inputProps={{ maxLength: 32 }}
              />
            </div>
            <div className="addbtn-container">
              <Button
                className="Addbtn"
                variant="contained"
                onClick={() => groupHandler()}
              >
                Add Group
              </Button>
            </div>
          </div>
          <Divider />
          {groupsList ? (
            <div>
              {groupsList.length > 0 ? (
                <div>
                  <TableContainer
                    style={{
                      width: "100%",
                      maxHeight: tableHeight + "px",
                      overflowY: "overlay",
                    }}
                  >
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow
                          style={{
                            borderBottom: "1px solid #000000",
                            background: "#f2f2f2",
                          }}
                        >
                          <TableCell className="table-heading" align="center">
                            Group Name
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            Group Users
                          </TableCell>
                          <TableCell className="table-heading" align="center">
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupsList.length > 0 &&
                          groupsList?.map((group, index) => {
                            return (
                              <TableRow
                                key={index}
                                style={{ background: "#f2f2f2" }}
                              >
                                <TableCell
                                  className="table-data"
                                  align="center"
                                >
                                  {group.group_name}
                                </TableCell>
                                <TableCell
                                  className="table-data"
                                  align="center"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <PeopleAltIcon />
                                    <PeopleAltIcon /> +
                                    {group.group_members.length}
                                    &nbsp;View Users
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="table-data"
                                  align="center"
                                >
                                  <div className="group-action">
                                    <div>
                                      <Button
                                        className="btn"
                                        variant="contained"
                                        onClick={(e) =>
                                          openeditgroup(
                                            group.group_id,
                                            group.group_name
                                          )
                                        }
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                    <div>
                                      <Button
                                        className="btn"
                                        variant="contained"
                                        onClick={() => {
                                          handleClickOpen(group.group_id);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="pagination">
                    <Pagination
                      count={count}
                      page={page}
                      onChange={handleChange}
                      size="small"
                      sx={{
                        "& button": {
                          fontSize: "12px",
                          fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                        },
                      }}
                    />
                  </div>
                  <Dialog
                    PaperProps={{
                      style: {
                        backgroundColor: "white",
                        boxShadow: "none",
                      },
                    }}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                      }}
                    >
                      "Do you really want to delete this Group ?"
                    </DialogTitle>
                    <DialogActions>
                      <Button
                        className="btn"
                        variant="contained"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          handleDeleteGroup(deletegroupid && deletegroupid)
                        }
                        autoFocus
                        className="btn"
                        variant="contained"
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    // background: "#f2f2f2",
                    fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                    fontSize: "16px",
                  }}
                >
                  <div>No results found</div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <Stack
                spacing={2}
                sx={{ display: "flex", flexDirection: "column", m: 2 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="rectangular" width={210} height={30} />
                  <Skeleton variant="rectangular" width={210} height={30} />
                </div>
              </Stack>
              <Stack
                spacing={2}
                sx={{ display: "flex", flexDirection: "column", m: 2 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="rectangular" width={210} height={30} />
                  <Skeleton variant="rectangular" width={210} height={30} />
                </div>
              </Stack>
              <Stack
                spacing={2}
                sx={{ display: "flex", flexDirection: "column", m: 2 }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="rectangular" width={210} height={30} />
                  <Skeleton variant="rectangular" width={210} height={30} />
                </div>
              </Stack>
            </div>
          )}
        </>
      ) : (
        <NewGroups
          onclose={backToListofGroups}
          getgroupname={getgroupname}
          addNewMember={handleNewMember}
        />
      )}
      <Dialog
        open={editgroup}
        sx={{
          "& .MuiPaper-root-MuiDialog-paper": {
            maxWidth: "700px",
          },
        }}
      >
        <EditModelTabs
          groupid={groupid}
          editgroupname={editgroupname}
          onclose={closeeditgroup}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default ListOfGroups;
