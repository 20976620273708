import React from "react";
import {
  TextField,
  InputAdornment,
  Container,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../../../../Styles/ChallengemodeStyles/ChallengeMode.css";
import ListOfGroups from "./Groups";
import UsersData from "./UsersData";
import axios from "axios";
import baseurl from "../../../../Config";
import AddFriend from "./SearchUser";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // style={{ background: "#f2f2f2",}}
    >
      {value === index && (
        <Container className="tabpanel-container">
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UsersDataComp = (props) => {
  const [value, setValue] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [followers, setfollowerslist] = React.useState("");
  const [following, setfollowinglist] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div className="users-container">
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: "rgba(0,0,0,.15)",
              display: "flex",
              justifyContent: "center",
              minHeight: "36px",
              height: "36px",
              alignItems: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ sx: { bgcolor: "#EC1087" } }}
              sx={{
                minHeight: "36px",
                height: "36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tab
                label="Search Users"
                {...a11yProps(0)}
                className="userTabs"
                sx={{
                  color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                  minHeight: "36px",
                  height: "36px",
                }}
              />
              <Tab
                label="Followers"
                {...a11yProps(1)}
                className="userTabs"
                sx={{
                  color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                  minHeight: "36px",
                  height: "36px",
                }}
              />
              <Tab
                label="Following"
                {...a11yProps(2)}
                className="userTabs"
                sx={{
                  color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                  minHeight: "36px",
                  height: "36px",
                }}
              />
              <Tab
                label="Groups"
                {...a11yProps(3)}
                className="userTabs"
                sx={{
                  color: value ? "#ffffff" : "rgba(0,0,0,0.5)",
                  minHeight: "36px",
                  height: "36px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AddFriend value={value} adduser={props.adduser} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UsersData value={value} adduser={props.adduser} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UsersData value={value} adduser={props.adduser} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ListOfGroups value={value} adduser={props.adduser} />
          </TabPanel>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default UsersDataComp;
