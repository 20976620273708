export function senduserseldata(sportsmenudata) {
  return {
    type: "senduserseldata",
    sportsmenudata,
  };
}

export function sendsportsarray(sportsdata) {
  return {
    type: "sportsdata",
    sportsdata,
  };
}

export function sendbetslipdata(betslipdata) {
  return {
    type: "sendbetslipdata",
    betslipdata,
  };
}

export function addUserData(userData) {
  return {
    type: "addUser",
    userData,
  };
}

export function addGroupsData(groupsData) {
  return {
    type: "groupUser",
    groupsData,
  };
}

export function sendteamdata(teamdata) {
  return {
    type: "sendteamsdata",
    teamdata,
  };
}

export function challengedata(challengedata) {
  return {
    type: "challengedata",
    challengedata,
  };
}

export function getsiteid(siteid) {
  return {
    type: "siteid",
    siteid,
  };
}

export function logindetails(logindetails) {
  return {
    type: "logindetails",
    logindetails,
  };
}

export function selectedUserData(selecteduserdata) {
  return {
    type: "selectUserData",
    selecteduserdata,
  };
}
export function gameData(gameData) {
  return {
    type: "gameData",
    gameData,
  };
}

export function uniqueGroupNames(uniqueGroupNames) {
  return {
    type: "uniqueGroupNames",
    uniqueGroupNames,
  };
}

export function showMatchData(showMatchData) {
  return {
    type: "showMatchData",
    showMatchData,
  };
}

export function initialeventdata(initialeventdata) {
  return {
    type: "getinitialeventdata",
    initialeventdata,
  };
}
export function isPrivateChallenge(isPrivateChallenge) {
  return {
    type: "isPrivateChallenge",
    isPrivateChallenge,
  };
}
export function showChallenger(showChallenger) {
  return {
    type: "showChallenger",
    showChallenger,
  };
}

export function wssporteventdata(wssporteventdata) {
  return {
    type: "wssporteventdata",
    wssporteventdata,
  };
}

export function wssportdata(wssportdata) {
  return {
    type: "wssportdata",
    wssportdata,
  };
}

export function subscribedata(subscribedata) {
  return {
    type: "subscribedata",
    subscribedata,
  };
}

export function ismatchesloading(ismatchesloading) {
  return {
    type: "ismatchesloading",
    ismatchesloading,
  };
}

export function displayMatchDetails(displayMatchDetails) {
  return {
    type: "displayMatchDetails",
    displayMatchDetails,
  };
}

export function setShowBetSlip(setShowBetSlip) {
  return {
    type: "setShowBetSlip",
    setShowBetSlip,
  };
}

export function showBetSlip(showBetSlip) {
  return {
    type: "showBetSlip",
    showBetSlip,
  };
}

export function setExptValue(setExptValue) {
  return {
    type: "setExptValue",
    setExptValue,
  };
}

export function setExptime(setExptime) {
  return {
    type: "setExptime",
    setExptime,
  };
}

export function isFirstStakeValueChanged(isFirstStakeValueChanged) {
  return {
    type: "isFirstStakeValueChanged",
    isFirstStakeValueChanged,
  };
}

export function firstStakeValue(firstStakeValue) {
  return {
    type: "firstStakeValue",
    firstStakeValue,
  };
}

export function isSecondStakeValueChanged(isSecondStakeValueChanged) {
  return {
    type: "isSecondStakeValueChanged",
    isSecondStakeValueChanged,
  };
}

export function secondStakeValue(secondStakeValue) {
  return {
    type: "secondStakeValue",
    secondStakeValue,
  };
}

export function isThirdStakeValueChanged(isThirdStakeValueChanged) {
  return {
    type: "isThirdStakeValueChanged",
    isThirdStakeValueChanged,
  };
}

export function thirdStakeValue(thirdStakeValue) {
  return {
    type: "thirdStakeValue",
    thirdStakeValue,
  };
}

export function initialcompetition(initialcompetition) {
  return {
    type: "initialcompetition",
    initialcompetition,
  };
}

export function restrictedmarkets(restrictedmarkets) {
  return {
    type: "restrictedmarkets",
    restrictedmarkets,
  };
}

export function mviewbackbtncmdcall(mviewbackbtncmdcall) {
  return {
    type: "mviewbackbtncmdcall",
    mviewbackbtncmdcall,
  };
}

export function sendIsLoading(isLoading) {
  return {
    type: "sendIsLoading",
    isLoading,
  };
}
