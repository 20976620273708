import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import logo from "../Assets/logo.png";

const loader = () => {
    return (
        <div style={
            {
                width: '100%',
                height: '100%',
                background: '#e5e5e5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:'column'
            }}>
            <img src={logo} alt="logoimage" className="logoimage" style={{width:'20vh', height:'20vh'}}></img>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress size={"4rem"} style={
                    {
                        color: '#ec1087'
                    }} />
            </Box>
        </div>
    )
}

export default loader