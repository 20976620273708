import React, { useEffect } from "react";
var CryptoJS = require("crypto-js");
const BSdata = localStorage.getItem("betslipdata");
var bytes = BSdata && CryptoJS.AES.decrypt(BSdata, "dt-crg-ggp2p");
var decryptedBSData = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
const teamdata = localStorage.getItem("teaminfo");

const allstates = {
  sidemenudata: {
    // sportname: 'Football',
    // sportid: 1,
    // regionid: 10001,
    // leaguename: "International Friendlies",
    // competitionid: 3042
  },
  matchdata: {
    gameid: 19637354,
  },
  gameData: {},
  uniqueGroupNames: [],
  showMatchData: true,
  initialeventdata: [],
  addUser: null,
  groupUser: null,
  isPrivateChallenge: false,
  showChallenger: false,
  displayMatchDetails: false,
  setShowBetSlip: false,
  showBetSlip: false,
  selectedUserData: null,
  followersdata: [],
  betslipmatchdata: decryptedBSData ? decryptedBSData : [],
  siteid: null,
  challengedata: {},
  teamdata: teamdata ? JSON.parse(teamdata) : [],
  wssporteventdata: null,
  subscribedata: "",
  ismatchesloading: true,
  setExptime: false,
  setExptValue: "",
  firstStakeValue: "",
  isFirstStakeValueChanged: false,
  secondStakeValue: "",
  isSecondStakeValueChanged: false,
  thirdStakeValue: "",
  isThirdStakeValueChanged: false,
  logindetails: {},
  initialcompetition: true,
  isactive: { active: [] },
  wssportdata: null,
  restrictedmarkets: [],
  mviewbackbtncmdcall: true,
  isLoading: false
};

export default function Reducer(state = allstates, actions) {
  switch (actions.type) {
    case "senduserseldata":
      return { ...state, sidemenudata: actions.sportsmenudata };
    case "sendMatchesData":
      return { ...state, matchdata: actions.matchesmenudata };
    case "sendbetslipdata":
      return { ...state, betslipmatchdata: actions.betslipdata };
    case "addUser":
      return { ...state, addUser: actions.userData };
    case "groupUser":
      return { ...state, groupUser: actions.groupsData };
    case "selectUserData":
      return { ...state, selectedUserData: actions.selecteduserdata };
    case "gameData":
      return { ...state, gameData: actions.gameData };
    case "uniqueGroupNames":
      return { ...state, uniqueGroupNames: actions.uniqueGroupNames };
    case "showMatchData":
      return { ...state, showMatchData: actions.showMatchData };
    case "isPrivateChallenge":
      return { ...state, isPrivateChallenge: actions.isPrivateChallenge };
    case "showChallenger":
      return { ...state, showChallenger: actions.showChallenger };
    case "sendteamsdata":
      return { ...state, teamdata: actions.teamdata };
    case "wssportdata":
      return { ...state, wssportdata: actions.wssportdata };
    case "displayMatchDetails":
      return { ...state, displayMatchDetails: actions.displayMatchDetails };
    case "setShowBetSlip":
      return { ...state, setShowBetSlip: actions.setShowBetSlip };
    case "showBetSlip":
      return { ...state, showBetSlip: actions.showBetSlip };
    case "wssporteventdata":
      return { ...state, wssporteventdata: actions.wssporteventdata };
    case "subscribedata":
      return { ...state, subscribedata: actions.subscribedata };
    case "challengedata":
      return { ...state, challengedata: actions.challengedata };
    case "siteid":
      return { ...state, siteid: actions.siteid };
    case "getinitialeventdata":
      return { ...state, initialeventdata: actions.initialeventdata };
    case "ismatchesloading":
      return { ...state, ismatchesloading: actions.ismatchesloading };
    case "setExptValue":
      return { ...state, setExptValue: actions.setExptValue };
    case "setExptime":
      return { ...state, setExptime: actions.setExptime };
    case "firstStakeValue":
      return { ...state, firstStakeValue: actions.firstStakeValue };
    case "isFirstStakeValueChanged":
      return {
        ...state,
        isFirstStakeValueChanged: actions.isFirstStakeValueChanged,
      };
    case "secondStakeValue":
      return { ...state, secondStakeValue: actions.secondStakeValue };
    case "logindetails":
      return { ...state, logindetails: actions.logindetails };
    case "initialcompetition":
      return { ...state, initialcompetition: actions.initialcompetition };
    case "restrictedmarkets":
      return { ...state, restrictedmarkets: actions.restrictedmarkets };
    case "mviewbackbtncmdcall":
      return { ...state, mviewbackbtncmdcall: actions.mviewbackbtncmdcall };
    case "sendIsLoading":
      return { ...state, isLoading: actions.isLoading };
    default:
      return state;
  }
}
